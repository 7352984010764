import React, {useState} from 'react';
import {Text, Platform} from 'react-native';
import {RHButton, RHSeparator} from 'src/common-components/custom-ui-helpers';
import {Colors, Typography} from 'src/styles';
import {Modal, TabBar, BaseScreen} from 'src/design-system';
import {createMaterialTopTabNavigator} from '@react-navigation/material-top-tabs';
import OrganizationSummary from '../../components/summary';
import Prompts from '../../components/prompts';
import Tags from '../../components/tags';
import {withDatabase} from '@nozbe/watermelondb/DatabaseProvider';
import withObservables from '@nozbe/with-observables';
import {compose} from 'recompose';
import RolesAndPermissions from 'src/modules/organization/components/roles-and-permissions';
import {of} from 'rxjs';
import withState from 'src/redux/wrapper';
import Timesheet from '../../components/timesheet';
import NoteTemplate from '../../components/note-template';
import {useStyle} from 'src/providers/style';
import Diagnoses from '../../components/diagnoses';
import Location from '../../components/location-tab';
import BillingEntity from '../../components/billing-entity-tab';
import AuthorizationsExport from '../../components/authorizations-export';

const Navigator = createMaterialTopTabNavigator();

const OrganizationProfile = ({organization, route, role}: any) => {
  const styles = useStyle();
  // const {edit = false}: {edit: boolean} = route.params;

  const [unsavedChanges, setUnsavedChanges] = useState(false);
  const [editMode, setEditMode] = useState<boolean>(false);

  const cancel = () => {
    setUnsavedChanges(false);
    setEditMode(false);
  };

  return (
    <BaseScreen header={false}>
      <Modal
        show={[unsavedChanges, setUnsavedChanges]}
        title={'Unsaved Changes'}
        footer={
          <>
            <RHButton
              secondary
              mode="outlined"
              textColor={Colors.RAVEN_BLACK}
              onPress={() => setUnsavedChanges(false)}>
              CANCEL
            </RHButton>
            <RHSeparator width={16} />
            <RHButton secondary mode="contained" onPress={cancel}>
              YES, REVERT
            </RHButton>
          </>
        }>
        <Text style={[Typography.P1, styles.textAlignCenter]}>
          You have unsaved changes.
        </Text>
        <Text style={[Typography.P1, styles.textAlignCenter]}>
          Are you sure you want to revert your changes?
        </Text>
      </Modal>

      <TabBar
        navigator={Navigator}
        location={'OrganizationProfile'}
        route={route}>
        <Navigator.Screen name="Summary">
          {() => (
            <OrganizationSummary
              organization={organization}
              edit={editMode}
              role={role}
              cancel={cancel}
              editMode={[editMode, setEditMode]}
              setUnsavedChanges={setUnsavedChanges}
            />
          )}
        </Navigator.Screen>
        {role?.promptView ? (
          <Navigator.Screen name="Prompts">
            {() => <Prompts role={role} />}
          </Navigator.Screen>
        ) : (
          <></>
        )}
        {role?.tagView ? (
          <Navigator.Screen name="Tags">
            {() => <Tags role={role} />}
          </Navigator.Screen>
        ) : (
          <></>
        )}
        {role?.roleView ? (
          <Navigator.Screen name="Roles and Permissions">
            {() => <RolesAndPermissions role={role} />}
          </Navigator.Screen>
        ) : (
          <></>
        )}
        {Platform.OS === 'web' && role?.timesheetView ? (
          <Navigator.Screen name="Timesheet">
            {() => <Timesheet />}
          </Navigator.Screen>
        ) : (
          <></>
        )}

        {Platform.OS === 'web' && role?.noteTemplateView ? (
          <Navigator.Screen name="Note Templates">
            {() => <NoteTemplate />}
          </Navigator.Screen>
        ) : (
          <></>
        )}
        {role?.organizationDiagnosisView ? (
          <Navigator.Screen name="Diagnoses">
            {() => <Diagnoses />}
          </Navigator.Screen>
        ) : (
          <></>
        )}
        {role?.organizationLocationView ? (
          <Navigator.Screen name="Locations">
            {() => <Location />}
          </Navigator.Screen>
        ) : (
          <></>
        )}
        {/* TODO: Determine need for new roles/permissions */}
        {/* {role?.organizationLocationView ? ( */}
        {Platform.OS === 'web' ? (
          <Navigator.Screen name="Billing Entities">
            {() => <BillingEntity />}
          </Navigator.Screen>
        ) : (
          <></>
        )}
        {/* ) : (
          <></>
        )} */}
        {Platform.OS === 'web' ? (
          <Navigator.Screen name="Authorizations">
            {() => <AuthorizationsExport />}
          </Navigator.Screen>
        ) : (
          <></>
        )}
      </TabBar>
    </BaseScreen>
  );
};

export default compose(
  withDatabase,
  withState,
  withObservables(['route'], ({route, database}: any) => ({
    organization: database?.get('instances').findAndObserve(route.params.id),
  })),
  withObservables(['authentication'], ({database, authentication}: any) => {
    return {
      profile: authentication?.userId
        ? database.get('users').findAndObserve(authentication.userId)
        : of(),
    };
  }),
  withObservables([], ({profile}: any) => {
    return {
      role: profile.role,
    };
  }),
)(OrganizationProfile);
