import React from 'react';
import {DataItem} from 'src/design-system';
import * as Yup from 'yup';
import {Text} from 'react-native';
import {Typography} from 'src/styles';
import {IDeveloperField} from 'dromo-uploader-react';
import SelectInput from 'src/hook-form-wrapper/select-input';
import {payers} from 'src/common-utils/payers-2025';
import {useTranslations} from 'src/providers/translation';

interface DisplayProps {
  value: string;
}

interface Props {
  name?: string;
}

export const Import = (
  key: string = 'payer',
  prefix?: string,
): IDeveloperField => ({
  label: (prefix ? prefix + ' ' : '') + 'Payer Name',
  key,
  validators: [
    {
      validate: 'required',
    },
  ],
});

export const Value = (value: string) => {
  return payers.find(item => item.value === value);
};

export const Display = ({value}: DisplayProps) => {
  const translations = useTranslations();

  return (
    <>
      <DataItem>
        <Text style={[Typography.H6]}>{translations('payer_name')}</Text>
        <Text style={[Typography.P3]}>{Value(value)?.label || ''}</Text>
      </DataItem>
      <DataItem>
        <Text style={[Typography.H6]}>{translations('payer_id')}</Text>
        <Text style={[Typography.P3]}>{Value(value)?.id || ''}</Text>
      </DataItem>
    </>
  );
};

export const Input = ({name}: Props) => {
  const translations = useTranslations();

  return (
    <SelectInput
      name={(name ? name + '.' : '') + 'payer'}
      label={translations('payer')}
      helper={''}
      required={false}
      items={payers}
    />
  );
};

export const validation = () => Yup.string().required('Payer name is required');

export default Input;
