import React, {useState} from 'react';
import {FlatList} from 'react-native';
import NoResults from 'src/common-components/noResults';
import {compose} from 'recompose';
import withObservables from '@nozbe/with-observables';
import {withDatabase} from '@nozbe/watermelondb/DatabaseProvider';
import {useDatabase} from '@nozbe/watermelondb/hooks';
import {Q} from '@nozbe/watermelondb';
import {useStyle} from 'src/providers/style';
import withState from 'src/redux/wrapper';
import {fuzzy} from 'src/common-utils/fuzzy';
import {mergeMap, of} from 'rxjs';
import DeleteModal from 'src/design-system/delete-modal';
import {useTranslations} from 'src/providers/translation';
import {BaseIndexScreen, Modal} from 'src/design-system';
import {Searchbar} from 'react-native-paper';
import {useSelector} from 'react-redux';
import BillingEntityForm from '../billing-entity-form';
import {BillingEntity, Role} from 'src/models';
import BillingEntityListItem from '../billing-entity-item';

interface Props {
  billingEntities: any[];
  role: Role;
}

const BillingEntityTab = ({role, billingEntities}: Props) => {
  const database = useDatabase();
  const translations = useTranslations();
  const styles = useStyle();

  const {selectedGroup, userId} = useSelector(state => state.authentication);

  const [searchFilter, setSearchFilter] = useState<string>('');
  const [show, setShow] = useState(false);
  const [showDelete, setShowDelete] = useState<string | boolean>(false);
  const [billingEntityState, setBillingEntityState] = useState(null);

  const title = billingEntityState
    ? 'Edit Billing Entity'
    : 'Create Billing Entity';

  const searchedItems = billingEntities?.filter((location: any) => {
    return fuzzy(location?.name, searchFilter);
  });

  const addCallback = () => {
    setBillingEntityState(null);
    setShow(true);
  };

  const onSubmit = async (values: any) => {
    console.log('values---', values);
    if (billingEntityState) {
      await billingEntityState.updateEntity({
        name: values.name,
        dba: values.dba,
        npi: values.npi,
        tin: values.tin,
        address: values.address,
        taxonomy: values.taxonomy,
      });
    } else {
      await database.write(async () => {
        return await database.get(BillingEntity.table).create(entity => {
          entity.partition = selectedGroup;
          entity.name = values.name;
          entity.dba = values.dba;
          entity.npi = values.npi;
          entity.tin = values.tin;
          entity.taxonomy = values.taxonomy;
          entity.address = values.address;

          entity.createdBy = userId;
          entity.updatedBy = userId;
        });
      });
    }
    setShow(false);
  };

  const deleteCancelled = () => {
    setShowDelete(false);
  };
  const deleteConfirmed = async (item: any) => {
    item.delete();
    setShowDelete(false);
  };
  const editCallBack = (item: any) => {
    setBillingEntityState(item);
    setShow(true);
  };

  return (
    <BaseIndexScreen
      title={'Billing Entities'}
      search={
        <Searchbar
          value={searchFilter}
          onChangeText={setSearchFilter}
          placeholder={translations('search')}
        />
      }
      add={addCallback}
      canAdd={role?.organizationLocationCreate || true}>
      <DeleteModal
        model={'Billing Entity'}
        text={showDelete ? `${showDelete?.name}` : null}
        show={[showDelete, setShowDelete]}
        cancelled={deleteCancelled}
        handleSubmit={() => deleteConfirmed(showDelete)}
      />
      <Modal show={[show, setShow]} title={title}>
        <BillingEntityForm
          billingEntity={billingEntityState}
          onAssign={onSubmit}
        />
      </Modal>
      {searchedItems?.length === 0 ? (
        <NoResults
          iconName="account-supervisor"
          message={'No Billing Entities Found'}
        />
      ) : (
        <FlatList
          data={billingEntities}
          scrollEnabled={true}
          keyExtractor={item => item?.id?.toString()}
          renderItem={({item}) => {
            return (
              <BillingEntityListItem
                billingEntity={item}
                canDelete={role?.organizationLocationDelete || true}
                canEdit={role?.organizationLocationEdit || true}
                deleteCallback={setShowDelete}
                editCallBack={editCallBack}
              />
            );
          }}
          style={[styles.flex]}
        />
      )}
    </BaseIndexScreen>
  );
};

export default compose(
  withDatabase,
  withState,
  withObservables(['authentication'], ({database, authentication}: any) => ({
    profile: authentication.userId
      ? database.get('users').findAndObserve(authentication.userId)
      : of({}),
  })),
  withObservables([], ({profile}: any) => {
    return {
      role: profile.role,
    };
  }),
  withObservables([], ({database, authentication}: any) => {
    return {
      instance: database
        ?.get('instances')
        .query(Q.where('_partition', authentication.selectedGroup))
        .observe()
        .pipe(mergeMap(x => x)),
    };
  }),
  withObservables(['instance'], ({database, authentication}: any) => ({
    billingEntities: database
      .get(BillingEntity.table)
      .query(
        Q.where('deleted_at', null),
        Q.where('_partition', authentication.selectedGroup),
      ),
  })),
)(BillingEntityTab);
