import React, {useEffect, useState} from 'react';
import {Platform, Text, View} from 'react-native';
import {FormProvider, useForm} from 'react-hook-form';
import {RHButton, RHSeparator} from 'src/common-components/custom-ui-helpers';
import {Colors, Typography} from 'src/styles';
import ProgramSummary from './summary';
import ProgramCollection from './collection';
import ProgramMeasurement from './measurement';
import Library from 'src/modules/programs/screens/program-list';
import {BaseScreen, Modal, TabBar} from 'src/design-system';
import {createMaterialTopTabNavigator} from '@react-navigation/material-top-tabs';
import BackButton from 'src/navigation/components/back-button';
import HeaderTitleLogo from 'src/navigation/components/header-logo';
import {withDatabase} from '@nozbe/watermelondb/DatabaseProvider';
import withObservables from '@nozbe/with-observables';
import {compose} from 'recompose';
import _ from 'lodash';
import {useDatabase} from '@nozbe/watermelondb/hooks';
import {useSelector} from 'react-redux';
import withState from 'src/redux/wrapper';
import {of} from 'rxjs';
import {ProgramPrompt, ProgramTag} from 'src/models';
import {useStyle} from 'src/providers/style';
import {yupResolver} from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import {ProgramName} from 'src/hook-form-inputs';
import ProgramProgress from './progress';

const Navigator = createMaterialTopTabNavigator();

const ProgramProfile = ({
  program,
  navigation,
  route,
  role,
  prompts,
  tags,
  targets,
}: any) => {
  const database = useDatabase();
  const styles = useStyle();
  const edit = route.params.edit;
  const {selectedGroup, userId} = useSelector(state => state.authentication);
  const isWeb = Platform.OS === 'web';

  const [editMode, setEditMode] = useState(edit);
  const [unsavedChanges, setUnsavedChanges] = useState(false);
  const validationSchema = Yup.object({
    name: ProgramName.validation(),
    method: Yup.string().required(),
    targets: Yup.array().when('method', {
      is: (val: string) => val === 'task_analysis' || val === 'trial_by_trial',
      then: () => Yup.array().min(1, 'At least one target is required'),
    }),
  });
  const methods = useForm({
    defaultValues: {
      baseline: program.baseline,
      baselineCorrectProbes: program.baselineCorrectProbes,
      baselineProbes: program.baselineProbes ? program.baselineProbes : 3,
      baselineSessions: program.baselineSessions ? program.baselineSessions : 3,
      interval: program.interval,
      intervalOccurs: program.intervalOccurs,
      intervalAutomation: program.intervalAutomation,
      intervalRecurrences: program.intervalRecurrences,
      intensity: program.intensity,
      maintenance: program.maintenance,
      maintenanceCadence: program.maintenanceCadence
        ? program.maintenanceCadence
        : 'daily',
      maintenanceCadenceMonthly: program.maintenanceCadenceMonthly,
      maintenanceCadenceWeekly: program.maintenanceCadenceWeekly
        ? program.maintenanceCadenceWeekly
        : 'Monday',
      maintenanceAllowance: program.maintenanceAllowance,
      maintenanceSessions: program.maintenanceSessions
        ? program.maintenanceSessions
        : 3,
      maintenanceValue: program.maintenanceValue,
      masterySessions: program.masterySessions ? program.masterySessions : 3,
      masteryStaff: program.masteryStaff,
      masteryStaffMinimum: program.masteryStaffMinimum,
      masteryValue: program.masteryValue,
      materialsNeeded: program.materialsNeeded,
      method: program.method,
      name: program.name,
      numberOfTrials: program.numberOfTrials,
      objective: program.objective,
      stimulusDescription: program.stimulusDescription,
      teachingInstructions: program.teachingInstructions,
      unit: program.unit ? program.unit : 'per_min',
      adjustment: program.adjustment ? program.adjustment : 'decrease',
      tags: tags.map((tag: any) => tag?.id),
      prompts: prompts.map((prompt: any) => prompt?.id),
      targets: targets.map((target: any) => target?.id),
    },
    resolver: yupResolver(validationSchema),
  });

  // TODO: Deprecate when we have the new affixed bottom
  const changeTitle = () => {
    navigation.setOptions({
      // eslint-disable-next-line react/no-unstable-nested-components
      headerRight: () => {
        return (
          <>
            {(
              program?.patientId ? role?.treatmentPlanEdit : role?.programEdit
            ) ? (
              <View style={[styles.row, styles.marginHorizontal]}>
                {!editMode ? (
                  <RHButton
                    mode="outlined"
                    icon="pencil"
                    textColor={Colors.RAVEN_BLACK}
                    onPress={() => {
                      setEditMode(true);
                    }}>
                    Edit
                  </RHButton>
                ) : (
                  <>
                    <RHButton
                      mode="outlined"
                      textColor={Colors.RAVEN_BLACK}
                      style={[styles.marginLRight]}
                      onPress={() => {
                        if (
                          Object.keys(methods.formState.dirtyFields).some(
                            field => field,
                          )
                        ) {
                          setUnsavedChanges(true);
                        } else {
                          cancel();
                        }
                      }}>
                      Cancel
                    </RHButton>
                    <RHButton
                      mode="contained"
                      icon="content-save"
                      color={Colors.TERTIARY_TEAL}
                      onPress={methods.handleSubmit(onSubmit, onError)}>
                      SAVE
                    </RHButton>
                  </>
                )}
              </View>
            ) : (
              <></>
            )}
          </>
        );
      },
      // eslint-disable-next-line react/no-unstable-nested-components
      headerLeft: () => (
        <>
          {!editMode ? (
            <BackButton customPress={() => navigateBack()} />
          ) : (
            <></>
          )}
        </>
      ),
      // eslint-disable-next-line react/no-unstable-nested-components
      headerTitle: () =>
        !editMode || Platform.OS === 'web' ? <HeaderTitleLogo /> : <></>,
    });
  };

  useEffect(() => {
    changeTitle();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editMode]);

  useEffect(() => {
    methods.setValue('targets', [...targets]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [targets]);

  const navigateBack = () => {
    setUnsavedChanges(false);
    if (navigation.canGoBack()) {
      navigation.goBack();
    } else {
      navigation.navigate(Library.screenName);
    }
  };

  const cancel = () => {
    setUnsavedChanges(false);
    setEditMode(false);
    methods.reset();
  };

  const onSubmit = async ({
    baseline,
    baselineCorrectProbes,
    baselineProbes,
    baselineSessions,
    interval,
    intervalAutomation,
    intervalOccurs,
    intervalRecurrences,
    intensity,
    maintenance,
    maintenanceCadence,
    maintenanceCadenceMonthly,
    maintenanceCadenceWeekly,
    maintenanceAllowance,
    maintenanceSessions,
    maintenanceValue,
    masterySessions,
    masteryStaff,
    masteryStaffMinimum,
    masteryValue,
    materialsNeeded,
    method,
    name,
    numberOfTrials,
    objective,
    stimulusDescription,
    teachingInstructions,
    unit,
    adjustment,
    tags: selectedTags,
    prompts: selectedPrompts,
  }: any) => {
    await program.updateEntity({
      baseline,
      baselineCorrectProbes,
      baselineProbes,
      baselineSessions,
      interval,
      intervalAutomation,
      intervalOccurs,
      intervalRecurrences: parseInt(intervalRecurrences, 10),
      intensity,
      maintenance,
      maintenanceCadence,
      maintenanceCadenceMonthly,
      maintenanceCadenceWeekly,
      maintenanceAllowance,
      maintenanceSessions,
      maintenanceValue,
      masterySessions,
      masteryStaff,
      masteryStaffMinimum,
      masteryValue,
      materialsNeeded,
      method,
      name,
      numberOfTrials,
      objective,
      stimulusDescription,
      teachingInstructions,
      unit,
      adjustment,
    });
    const programTags = await program.programTags.fetch();
    const deleteTags = _.differenceWith(
      programTags,
      selectedTags,
      (value1, value2) => {
        return value1.tag_id === value2;
      },
    );
    const differenceTags: string[] = _.differenceWith(
      selectedTags,
      programTags,
      (value1, value2) => {
        return value1 === value2.id;
      },
    );
    for (const tag of deleteTags) {
      tag.delete();
    }
    for (const tag of differenceTags) {
      database.write(async () => {
        await database.get(ProgramTag.table).create(entity => {
          entity.partition = selectedGroup;
          entity.program.id = program.id;
          entity.tag.id = tag;
          entity.createdBy = userId;
          entity.updatedBy = userId;
        });
      });
    }
    const programPrompts = await program.programPrompts.fetch();
    const deletePrompts = _.differenceWith(
      programPrompts,
      selectedPrompts,
      (value1, value2) => {
        return value1.prompt_id === value2;
      },
    );
    const differencePrompts = _.differenceWith(
      selectedPrompts,
      programPrompts,
      (value1, value2) => {
        return value1 === value2.id;
      },
    );
    for (const prompt of deletePrompts) {
      prompt.delete();
    }
    for (const prompt of differencePrompts) {
      database.write(async () => {
        await database.get(ProgramPrompt.table).create(entity => {
          entity.partition = selectedGroup;
          entity.program.id = program.id;
          entity.prompt.id = prompt;
          entity.createdBy = userId;
          entity.updatedBy = userId;
        });
      });
    }
    setEditMode(false);
  };

  const onError = (tabStatusErrors: any) => {
    // TODO: Navigate to tab and validation failure
    console.log(tabStatusErrors);
  };
  return (
    <BaseScreen header={false}>
      <Modal
        show={[unsavedChanges, setUnsavedChanges]}
        title={'Unsaved Changes'}
        footer={
          <>
            <RHButton
              secondary
              mode="outlined"
              textColor={Colors.RAVEN_BLACK}
              onPress={() => setUnsavedChanges(false)}>
              CANCEL
            </RHButton>
            <RHSeparator width={16} />
            <RHButton secondary mode="contained" onPress={cancel}>
              YES, REVERT
            </RHButton>
          </>
        }>
        <Text style={[Typography.P1, styles.textAlignCenter]}>
          You have unsaved changes.
        </Text>
        <Text style={[Typography.P1, styles.textAlignCenter]}>
          Are you sure you want to revert your changes?
        </Text>
      </Modal>
      <TabBar
        navigator={Navigator}
        location={ProgramProfile.screenName}
        route={route}>
        <Navigator.Screen name="Summary">
          {() => (
            <FormProvider {...methods}>
              <ProgramSummary
                control={methods.control}
                errors={methods.formState.errors}
                edit={editMode}
                setFocus={methods.setFocus}
                values={methods.getValues()}
              />
            </FormProvider>
          )}
        </Navigator.Screen>
        {program?.patientId && isWeb ? (
          <Navigator.Screen name="Dashboard">
            {() => (
              <ProgramProgress
                program={program}
                patientId={program?.patientId}
              />
            )}
          </Navigator.Screen>
        ) : (
          <></>
        )}
        <Navigator.Screen name="Collection">
          {() => (
            <FormProvider {...methods}>
              <ProgramCollection
                control={methods.control}
                errors={methods.formState.errors}
                edit={editMode}
                values={program}
                programType={program?.type}
                setFocus={methods.setFocus}
                clearErrors={methods.clearErrors}
                targets={targets}
              />
            </FormProvider>
          )}
        </Navigator.Screen>
        <Navigator.Screen name="Measurement">
          {() => (
            <FormProvider {...methods}>
              <ProgramMeasurement
                control={methods.control}
                errors={methods.formState.errors}
                edit={editMode}
                values={program}
                programType={program?.type}
                program={program}
              />
            </FormProvider>
          )}
        </Navigator.Screen>
      </TabBar>
    </BaseScreen>
  );
};

ProgramProfile.screenName = 'ProgramProfile';
ProgramProfile.title = 'Program Profile';

export default compose(
  withDatabase,
  withState,
  withObservables(['authentication'], ({database, authentication}: any) => ({
    profile: authentication.userId
      ? database.get('users').findAndObserve(authentication.userId)
      : of(),
  })),
  withObservables([], ({profile}: any) => {
    return {
      role: profile.role,
    };
  }),
  withObservables(['route'], ({route, database}: any) => ({
    program: database.get('programs').findAndObserve(route.params.programId),
  })),
  withObservables([], ({program}: any) => ({
    prompts: program.prompts,
    tags: program.tags,
    targets: program.enabledTargets,
  })),
)(ProgramProfile);
