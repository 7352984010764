import {json, text} from '@nozbe/watermelondb/decorators';
import {Base} from 'src/models/base';
import {sanitizeStrings} from 'src/common-utils/sanitize-strings';

export class BillingEntity extends Base {
  static table = 'billing_entities';

  @text('name') name;
  @text('dba') dba;
  @json('address', value => value) address;
  @text('tin') tin;
  @text('npi') npi;
  @json('taxonomy', sanitizeStrings) taxonomy;
}
