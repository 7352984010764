import {synchronize} from '@nozbe/watermelondb/sync';
import {SYNC_SERVER} from 'react-native-dotenv';
import AsyncStorage from '@react-native-async-storage/async-storage';
import {authApi} from 'src/services/auth';
import {store} from 'src/redux/store';
import {uuid} from 'src/common-utils/uuid';
import {Platform} from 'react-native';
import logger from '@nozbe/watermelondb/utils/common/logger';
import {Mutex} from 'async-mutex';
import Bugsnag from 'src/common-utils/bugsnag-set-user';

logger.silence();

const mutex = new Mutex();
const models = {
  instances: true,
  roles: true,
  users: true,
  patients: true,
  care_team_participants: true,
  appointments: true,
  participants: true,
  note_templates: true,
  note_template_versions: true,
  sessions: true,
  programs: true,
  program_prompt: true,
  prompts: true,
  targets: true,
  authorizations: true,
  caregivers: true,
  instance_diagnoses: true,
  diagnoses: true,
  medications: true,
  tags: true,
  claims: true,
  claim_events: true,
  service_lines: true,
  locations: true,
  payers: true,
  payer_credential: true,
  payer_plans: true,
  insurances: true,
  credentials: true,
  billing_entities: true,
  taggables: true,
  assessments: true,
  progress_reports: true,
  progress_report_program: true,
  progress_report_assessment: true,
  progress_report_caregiver: true,
  sets: true,
  events: true,
  notes: true,
  signatures: true,
};

const syncModels = [
  'notes',
  'signatures',
  'authorizations',
  'caregivers',
  'instance_diagnoses',
  'diagnoses',
  'medications',
  'tags',
  'claims',
  'claim_events',
  'service_lines',
  'locations',
  'payers',
  'payer_credential',
  'payer_plans',
  'insurances',
  'credentials',
  'taggables',
  'assessments',
  'progress_reports',
  'progress_report_program',
  'progress_report_assessment',
  'progress_report_caregiver',
  'documents',
  'environmental_factors',
  'program_tag',
  'session_programs',
  'session_targets',
  'notifications',
  'remits',
  'remit_claim',
  'transactions',
];
//sync last 12 months
const sessionData = ['sets', 'events'];

const delay = ms => new Promise(res => setTimeout(res, ms));

const exponentialBackoff = async (
  syncId: string,
  currentTable: string | null,
  lastTimestamp: number,
  schemaVersion: string,
  pushyToken: string | null,
  retries = 0,
  // onOrganizationSelect: boolean | false,
) => {
  let response;
  const now = new Date();

  const eightMosAgo = new Date(
    now.getFullYear(),
    now.getMonth() - 8,
    now.getDate(),
  );
  const oneDayAgo = new Date(
    now.getFullYear(),
    now.getMonth(),
    now.getDate() - 1,
  );
  const timestamp = eightMosAgo.getTime();
  const earlyTimestamp = oneDayAgo.getTime();

  let syncTimestamp = currentTable ? 0 : lastTimestamp;
  if (currentTable && syncModels.includes(currentTable)) {
    syncTimestamp = earlyTimestamp;
  } else if (currentTable && sessionData.includes(currentTable)) {
    syncTimestamp = timestamp;
  } else if (currentTable) {
    syncTimestamp = 0;
  } else {
    syncTimestamp = lastTimestamp;
  }

  if (retries < 5) {
    await delay(retries * 1000);
    try {
      response = await fetch(
        `${SYNC_SERVER}/sync?` +
          `syncId=${syncId}` +
          `&lastPulledAt=${syncTimestamp}` +
          `&schemaVersion=${schemaVersion}` +
          `&pushyToken=${pushyToken}` +
          `&platform=${Platform.OS}` +
          (currentTable ? `&table=${currentTable}` : ''),
        {
          method: 'GET',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${await AsyncStorage.getItem(
              'accessToken',
            )}`,
          },
        },
      );
    } catch (e) {
      response = await exponentialBackoff(
        syncId,
        currentTable,
        lastTimestamp,
        schemaVersion,
        pushyToken,
        ++retries,
      );
    }
  }
  return response;
};

export default async function sync(
  database: any,
  turbo: boolean = false,
  table = false,
  onInitialize = false,
  // onLanding = false,
  onDashboard = false,
  finishSync = false,
) {
  const log = {};

  const lastTimestamp =
    (await database.localStorage.get('__watermelon_last_pulled_at')) ||
    Date.now();

  let currentTable: string | null = null;
  let onOrganizationSelect: boolean | false;

  const pullChanges = async ({schemaVersion}: any) => {
    try {
      let syncId = await AsyncStorage.getItem('syncId');
      if (!syncId) {
        syncId = uuid()?.toLowerCase();
        await AsyncStorage.setItem('syncId', syncId);
      }

      const pushyToken = await AsyncStorage.getItem('pushyToken');

      const response = await exponentialBackoff(
        syncId,
        currentTable,
        lastTimestamp,
        schemaVersion,
        pushyToken,
        0,
        onOrganizationSelect,
      );

      if (response.status === 401) {
        const refreshToken = await AsyncStorage.getItem('refreshToken');
        if (!refreshToken) {
          throw new Error(await response.text());
        }
        const refreshData = new FormData();
        refreshData.append('refresh_token', refreshToken);
        refreshData.append('grant_type', 'refresh_token');

        const refreshTokenResponse = await store.dispatch(
          authApi.endpoints.token.initiate(refreshData),
        );
        if (
          refreshTokenResponse?.error &&
          refreshTokenResponse?.error.status === 401
        ) {
          await AsyncStorage.clear();
          await AsyncStorage.setItem('syncId', syncId);
        } else {
          return await pullChanges({schemaVersion});
        }
      } else if (!response.ok) {
        throw new Error(await response.text());
      }

      if (turbo) {
        const json = await response.text();
        return {syncJson: json};
      } else {
        const value = await response.json();

        if (__DEV__) {
          console.log(value);
        }

        return value;
      }
    } catch (e) {
      console.log(e);
    }
  };
  const pushChanges = async ({changes, lastPulledAt}: any) => {
    const response = await fetch(
      `${SYNC_SERVER}/sync?lastPulledAt=${lastPulledAt}`,
      {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${await AsyncStorage.getItem('accessToken')}`,
        },
        body: JSON.stringify(changes),
      },
    );

    if (response.status === 401) {
      const refreshToken = await AsyncStorage.getItem('refreshToken');
      if (!refreshToken) {
        throw new Error(await response.text());
      }
      const refreshData = new FormData();
      refreshData.append('refresh_token', refreshToken);
      refreshData.append('grant_type', 'refresh_token');

      const refreshTokenResponse = await store.dispatch(
        authApi.endpoints.token.initiate(refreshData),
      );
      if (
        refreshTokenResponse?.error &&
        refreshTokenResponse?.error.status === 401
      ) {
        const syncId = (await AsyncStorage.getItem('syncId'))?.toLowerCase();
        await AsyncStorage.clear();
        await AsyncStorage.setItem('syncId', syncId);
      } else {
        return await pushChanges({changes, lastPulledAt});
      }
    } else if (!response.ok) {
      throw new Error(await response.text());
    }
  };
  if (!mutex.isLocked()) {
    const release = await mutex.acquire();
    try {
      if (table) {
        for (const perspectiveTable of Object.keys(models)) {
          //sync necessary tables
          if (
            !syncModels.includes(perspectiveTable) &&
            !sessionData.includes(perspectiveTable)
          ) {
            currentTable = perspectiveTable;
            try {
              await synchronize({
                database,
                log,
                pullChanges,
                pushChanges,
                unsafeTurbo: turbo,
                _unsafeBatchPerCollection: !turbo,
              });
            } catch (e) {
              await delay(500);
              try {
                await synchronize({
                  database,
                  log,
                  pullChanges,
                  pushChanges,
                  unsafeTurbo: turbo,
                  _unsafeBatchPerCollection: !turbo,
                });
              } catch (err) {
                await delay(1000);
                try {
                  await synchronize({
                    database,
                    log,
                    pullChanges,
                    pushChanges,
                    unsafeTurbo: turbo,
                    _unsafeBatchPerCollection: !turbo,
                  });
                } catch (error) {
                  Bugsnag.notify(
                    `sync error ${currentTable} ${(
                      await AsyncStorage.getItem('syncId')
                    )?.toLowerCase()}`,
                  );
                }
              }
            }
          } else if (sessionData.includes(perspectiveTable)) {
            currentTable = perspectiveTable;
            onOrganizationSelect = true;
            try {
              await synchronize({
                database,
                log,
                pullChanges,
                pushChanges,
                unsafeTurbo: turbo,
                _unsafeBatchPerCollection: !turbo,
              });
            } catch (e) {
              await delay(500);
              try {
                await synchronize({
                  database,
                  log,
                  pullChanges,
                  pushChanges,
                  unsafeTurbo: turbo,
                  _unsafeBatchPerCollection: !turbo,
                });
              } catch (err) {
                await delay(1000);
                try {
                  await synchronize({
                    database,
                    log,
                    pullChanges,
                    pushChanges,
                    unsafeTurbo: turbo,
                    _unsafeBatchPerCollection: !turbo,
                  });
                } catch (error) {
                  Bugsnag.notify(
                    `sync error ${currentTable} ${(
                      await AsyncStorage.getItem('syncId')
                    )?.toLowerCase()}`,
                  );
                }
              }
            }
          }
        }
        currentTable = null;
        onOrganizationSelect = false;
        await synchronize({
          database,
          log,
          pullChanges,
          pushChanges,
          unsafeTurbo: turbo,
          _unsafeBatchPerCollection: !turbo,
        });
      } else if (onDashboard) {
        for (const newTable of syncModels) {
          //sync necessary tables
          currentTable = newTable;
          try {
            await synchronize({
              database,
              log,
              pullChanges,
              pushChanges,
              unsafeTurbo: turbo,
              _unsafeBatchPerCollection: !turbo,
            });
          } catch (e) {
            await delay(500);
            try {
              await synchronize({
                database,
                log,
                pullChanges,
                pushChanges,
                unsafeTurbo: turbo,
                _unsafeBatchPerCollection: !turbo,
              });
            } catch (err) {
              await delay(1000);
              try {
                await synchronize({
                  database,
                  log,
                  pullChanges,
                  pushChanges,
                  unsafeTurbo: turbo,
                  _unsafeBatchPerCollection: !turbo,
                });
              } catch (error) {
                Bugsnag.notify(
                  `sync error ${currentTable} ${(
                    await AsyncStorage.getItem('syncId')
                  )?.toLowerCase()}`,
                );
              }
            }
          }
        }
        currentTable = null;
        await synchronize({
          database,
          log,
          pullChanges,
          pushChanges,
          unsafeTurbo: turbo,
          _unsafeBatchPerCollection: !turbo,
        });
      } else if (finishSync) {
        for (const dataTable of sessionData) {
          //sync necessary tables
          currentTable = dataTable;
          try {
            await synchronize({
              database,
              log,
              pullChanges,
              pushChanges,
              unsafeTurbo: turbo,
              _unsafeBatchPerCollection: !turbo,
            });
          } catch (e) {
            await delay(500);
            try {
              await synchronize({
                database,
                log,
                pullChanges,
                pushChanges,
                unsafeTurbo: turbo,
                _unsafeBatchPerCollection: !turbo,
              });
            } catch (err) {
              await delay(1000);
              try {
                await synchronize({
                  database,
                  log,
                  pullChanges,
                  pushChanges,
                  unsafeTurbo: turbo,
                  _unsafeBatchPerCollection: !turbo,
                });
              } catch (error) {
                Bugsnag.notify(
                  `sync error ${currentTable} ${(
                    await AsyncStorage.getItem('syncId')
                  )?.toLowerCase()}`,
                );
              }
            }
          }
        }
        currentTable = null;
        await synchronize({
          database,
          log,
          pullChanges,
          pushChanges,
          unsafeTurbo: turbo,
          _unsafeBatchPerCollection: !turbo,
        });
      } else {
        await synchronize({
          database,
          log,
          pullChanges,
          pushChanges,
          unsafeTurbo: turbo,
          _unsafeBatchPerCollection: !turbo,
        });
      }
    } catch (e) {
      if (onInitialize) {
        Bugsnag.notify(`db initialization failed: ${e}`);
        throw new Error(`initialization failure: ${e}`);
      }

      console.log(`sync failure: ${e}`);
    } finally {
      release();
    }
  }
}
