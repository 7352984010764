import React, {useEffect, useImperativeHandle, useRef} from 'react';
import {Text, View} from 'react-native';
import {useStyle} from 'src/providers/style';
import {Typography} from 'src/styles';
import {compose} from 'recompose';
import {Q} from '@nozbe/watermelondb';
import withObservables from '@nozbe/with-observables';
import withState from 'src/redux/wrapper';
import {withDatabase} from '@nozbe/watermelondb/DatabaseProvider';
import {mergeMap} from 'rxjs';
import FileImage from 'src/design-system/file-image';
import {Separator} from 'src/common-components/atoms';
import {Display as ClientInfoDisplay} from '../client-info';
import {Display as BackgroundDisplay} from '../background';
import {Display as GoalsDisplay} from '../goals';
import {Display as CaregiverDisplay} from '../caregiver';
import {Display as SummaryDisplay} from '../summary';
import {Display as AssessmentDisplay} from 'src/modules/patients/components/assessment-form';
import {Display as BehaviorGoalsDisplay} from 'src/modules/patients/screens/progress-report-screen/components/behavior-goals';
import {Display as SkillGoalsDisplay} from 'src/modules/patients/screens/progress-report-screen/components/skill-goals';
import DateInput from 'src/hook-form-wrapper/date-input';
import {of} from 'rxjs';
import moment from 'moment';
import {SafeAreaProvider} from 'react-native-safe-area-context';
import {useFormContext, useWatch} from 'react-hook-form';
import ViewShot from 'react-native-view-shot';

const Review = ({
  assessments,
  progressReport,
  diagnoses,
  patient,
  organization,
  behaviorAuthPrograms,
  skillAuthPrograms,
  progressReportCaregivers,
  clientEnvs,
  signatures,
  activeInsurances,
  fromRecap = false,
  reviewRef,
  shouldShowMenu = false,
}: any) => {
  const styles = useStyle();
  const {control} = useFormContext();

  const watchedStartDate = useWatch({
    control,
    name: 'startDate',
  });

  const watchedEndDate = useWatch({
    control,
    name: 'endDate',
  });

  useEffect(() => {
    progressReport.updateEntity({
      startDate: watchedStartDate,
      endDate: watchedEndDate,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchedStartDate, watchedEndDate]);

  const {
    summaryInclude,
    crisisPlanInclude,
    transitionPlanInclude,
    recommendationsInclude,
    signatureInclude,
  } = progressReport;

  const clientInfoRef = useRef(null);
  const backgroundRef = useRef(null);
  const assessmentsRef = useRef([]);
  const goalsRef = useRef(null);
  const behaviorGoalsRefs = useRef([]);
  const skillGoalsRefs = useRef([]);
  const caregiverRef = useRef(null);
  const summaryRef = useRef([]);

  // Expose the refs to the parent using useImperativeHandle
  useImperativeHandle(reviewRef, () => ({
    clientInfoRef,
    backgroundRef,
    assessmentsRef,
    goalsRef,
    behaviorGoalsRefs,
    skillGoalsRefs,
    caregiverRef,
    summaryRef,
  }));

  return (
    <View style={[styles.padding, styles.backgroundColorWhite]}>
      <ViewShot ref={clientInfoRef}>
        <View style={[styles.row, styles.paddingVertical]}>
          {organization?.logo ? (
            <View
              style={[
                styles.alignCenter,
                styles.justifyCenter,
                styles.marginMRight,
                styles.overflowHidden,
                styles.borderRadius,
                styles.width50,
                styles.height50,
              ]}>
              <FileImage src={organization?.logo} />
            </View>
          ) : (
            <></>
          )}
          <Separator width={10} />
          <View style={[styles.alignCenter, styles.justifyCenter]}>
            <Text style={[Typography.H4]}>{organization.name}</Text>
          </View>
        </View>
        <Text style={[Typography.H4, styles.paddingVertical]}>
          Progress Report
        </Text>
        {fromRecap ? (
          <>
            <Text style={[Typography.H5]}>
              Start Date:{' '}
              {moment(progressReport.startDate).format('MM/DD/YYYY')}
            </Text>
            <Text style={[Typography.H5]}>
              End Date: {moment(progressReport.endDate).format('MM/DD/YYYY')}
            </Text>
          </>
        ) : (
          <View
            style={[
              styles.container,
              styles.paddingBottom,
              styles.maxWidth540,
            ]}>
            <SafeAreaProvider>
              <DateInput
                name={'startDate'}
                label={'Start Date'}
                placeholder={'Start Date'}
                showHelper={false}
                required={false}
              />
            </SafeAreaProvider>
            <Separator width={20} />
            <SafeAreaProvider>
              <DateInput
                name={'endDate'}
                label={'End Date'}
                placeholder={'End Date'}
                showHelper={false}
                required={false}
              />
            </SafeAreaProvider>
          </View>
        )}
        <Text style={[Typography.H5, styles.paddingTop]}>
          Completed Date:{' '}
          {progressReport.submittedAt > 0
            ? `${moment(progressReport.submittedAt).format('L')} at ${moment(
                progressReport.submittedAt,
              ).format('LT')}`
            : 'In Progress'}
        </Text>
        <ClientInfoDisplay
          patient={patient}
          diagnoses={diagnoses}
          progressReportCaregivers={progressReportCaregivers}
          activeInsurances={activeInsurances}
        />
      </ViewShot>

      {progressReport.backgroundInfoInclude ||
      progressReport.medicalInfoInclude ? (
        <ViewShot ref={backgroundRef}>
          <BackgroundDisplay progressReport={progressReport} />
        </ViewShot>
      ) : (
        <></>
      )}

      {assessments.length ? (
        <View style={[styles.paddingMVertical]}>
          <Text style={[Typography.H4, styles.paddingSMBottom]}>
            {patient.firstName}'s Assessments
          </Text>
          {assessments.map((assessment, index) => {
            // eslint-disable-next-line react-hooks/rules-of-hooks
            const singleAssessmentRef = useRef(null);
            assessmentsRef.current[index] = singleAssessmentRef;

            return (
              <ViewShot key={assessment.id} ref={singleAssessmentRef}>
                <View key={assessment.id} style={[styles.paddingVertical]}>
                  <Text style={[Typography.H5, styles.paddingSMBottom]}>
                    Assessment #{index + 1}
                  </Text>
                  <AssessmentDisplay
                    assessment={assessment}
                    patient={patient}
                  />
                </View>
              </ViewShot>
            );
          })}
        </View>
      ) : (
        <></>
      )}

      {progressReport.behaviorInterventionPlanInclude ? (
        <ViewShot ref={goalsRef}>
          <GoalsDisplay progressReport={progressReport} />
        </ViewShot>
      ) : (
        <></>
      )}

      {behaviorAuthPrograms.length ? (
        <View style={[styles.paddingMVertical]}>
          <Text style={[Typography.H5, styles.paddingSMVertical]}>
            Behavior Goals
          </Text>
          <View style={[styles.paddingMVertical]}>
            {behaviorAuthPrograms.map((behaviorAuthProgram, index) => {
              // eslint-disable-next-line react-hooks/rules-of-hooks
              const singleBehaviorRef = useRef();
              behaviorGoalsRefs.current[index] = singleBehaviorRef;
              return (
                <ViewShot key={behaviorAuthProgram.id} ref={singleBehaviorRef}>
                  <BehaviorGoalsDisplay
                    authProgram={behaviorAuthProgram}
                    key={behaviorAuthProgram.id}
                    clientEnvs={clientEnvs}
                    patient={patient}
                    progressReport={progressReport}
                    shouldShowMenu={shouldShowMenu}
                  />
                </ViewShot>
              );
            })}
          </View>
        </View>
      ) : (
        <></>
      )}

      {skillAuthPrograms.length ? (
        <View style={[styles.paddingMVertical]}>
          <Text style={[Typography.H5, styles.paddingSMVertical]}>
            Skill Acquisition Goals
          </Text>
          <View style={[styles.paddingMVertical]}>
            {skillAuthPrograms.map((skillAuthProgram, index) => {
              // eslint-disable-next-line react-hooks/rules-of-hooks
              const singleSkillRef = useRef(null);
              skillGoalsRefs.current[index] = singleSkillRef;

              return (
                <ViewShot key={skillAuthProgram.id} ref={singleSkillRef}>
                  <SkillGoalsDisplay
                    authProgram={skillAuthProgram}
                    key={skillAuthProgram.id}
                    clientEnvs={clientEnvs}
                    patient={patient}
                    progressReport={progressReport}
                    shouldShowMenu={shouldShowMenu}
                  />
                </ViewShot>
              );
            })}
          </View>
        </View>
      ) : (
        <></>
      )}

      {progressReport.caregiverGuidanceInfoInclude ? (
        <ViewShot ref={caregiverRef}>
          <CaregiverDisplay progressReport={progressReport} />
        </ViewShot>
      ) : (
        <></>
      )}
      {summaryInclude ||
      crisisPlanInclude ||
      transitionPlanInclude ||
      recommendationsInclude ||
      signatureInclude ? (
        <SummaryDisplay
          progressReport={progressReport}
          signatures={signatures}
          summaryRef={summaryRef}
        />
      ) : (
        <></>
      )}
    </View>
  );
};

export default compose(
  withDatabase,
  withState,
  withObservables(
    ['progressReport'],
    ({progressReport, authentication, database}: any) => ({
      assessments: progressReport.assessments,
      behaviorAuthPrograms: progressReport.behaviorAuthPrograms,
      skillAuthPrograms: progressReport.skillAuthPrograms,
      patient: progressReport.patient,
      signatures: progressReport.progressReportSignatures,
      organization: database
        ?.get('instances')
        .query(Q.where('_partition', authentication.selectedGroup))
        .observe()
        .pipe(mergeMap(x => x)),
      progressReportCaregivers: progressReport.progressReportCaregivers,
    }),
  ),
  withObservables([], ({patient}: any) => ({
    diagnoses: patient?.activeDiagnoses,
    clientEnvs: patient?.activeEnvironmentalFactors || of([]),
    activeInsurances: patient?.activeInsurances,
  })),
)(Review);
