import WatermelonDBAdapter from 'src/providers/watermelondb/adapter';
import schema from 'src/providers/watermelondb/model/schema';
import migrations from 'src/providers/watermelondb/model/migrations';
import {
  Appointment,
  Assessment,
  Authorization,
  BillingEntity,
  Caregiver,
  CareTeamParticipant,
  Claim,
  ClaimEvent,
  Credential,
  Diagnosis,
  Document,
  EnvironmentalFactor,
  Event,
  Instance,
  InstanceDiagnosis,
  Location,
  Payer,
  PayerCredential,
  Insurance,
  PayerPlans,
  Medication,
  Note,
  Notification,
  Participant,
  Patient,
  Program,
  ProgramPrompt,
  ProgramTag,
  ProgressReport,
  ProgressReportAssessment,
  ProgressReportCaregiver,
  ProgressReportProgram,
  Prompt,
  Role,
  Session,
  SessionProgram,
  SessionTarget,
  Set,
  Tag,
  Target,
  User,
  NoteTemplate,
  NoteTemplateVersion,
  Signature,
  ServiceLine,
  Taggable,
} from 'src/models';
import {Database} from '@nozbe/watermelondb';
import {Transaction} from 'src/models/transaction';
import {Remit} from 'src/models/remit';
import {RemitClaim} from 'src/models/remit_claim';
const adapter = WatermelonDBAdapter(schema, migrations);

const database = new Database(
  {
    adapter,
    modelClasses: [
      Appointment,
      Assessment,
      Authorization,
      BillingEntity,
      Caregiver,
      CareTeamParticipant,
      Claim,
      ClaimEvent,
      Credential,
      Diagnosis,
      Document,
      EnvironmentalFactor,
      Event,
      Instance,
      InstanceDiagnosis,
      Location,
      Payer,
      PayerCredential,
      Insurance,
      PayerPlans,
      Medication,
      Note,
      Notification,
      Participant,
      Patient,
      Program,
      ProgramPrompt,
      ProgramTag,
      ProgressReport,
      ProgressReportAssessment,
      ProgressReportCaregiver,
      ProgressReportProgram,
      Prompt,
      Role,
      Session,
      SessionProgram,
      SessionTarget,
      Set,
      Tag,
      Target,
      User,
      NoteTemplate,
      NoteTemplateVersion,
      Signature,
      ServiceLine,
      Taggable,
      Remit,
      RemitClaim,
      Transaction,
    ],
  },
  {throttledSaves: true},
);

export default database;
export * from './sync';
