import {Q} from '@nozbe/watermelondb';
import {
  children,
  date,
  field,
  json,
  lazy,
  relation,
  text,
} from '@nozbe/watermelondb/decorators';
import {Base} from 'src/models/base';
export class Patient extends Base {
  static table = 'patients';
  static associations = {
    caregivers: {type: 'has_many', foreignKey: 'patient_id'},
    users: {type: 'belongs_to', key: 'staff_id'},
    programs: {type: 'has_many', foreignKey: 'patient_id'},
    care_team_participants: {type: 'has_many', foreignKey: 'patient_id'},
    participants: {type: 'has_many', foreignKey: 'patient_id'},
    authorizations: {type: 'has_many', foreignKey: 'patient_id'},
    environmental_factors: {type: 'has_many', foreignKey: 'patient_id'},
    documents: {type: 'has_many', foreignKey: 'patient_id'},
    diagnoses: {type: 'has_many', foreignKey: 'patient_id'},
    medications: {type: 'has_many', foreignKey: 'patient_id'},
    insurances: {type: 'has_many', foreignKey: 'patient_id'},
    credentials: {type: 'belongs_to', foreignKey: 'primary_id'},
    taggables: {type: 'has_many', foreignKey: 'entity_id'},
    // credentials: {type: 'belongs_to', foreignKey: 'referring_id'},
    progress_reports: {type: 'has_many', foreignKey: 'patient_id'},
  };

  @children('insurances') insurances;

  @lazy
  activeInsurances = this.insurances.extend(
    Q.where('deleted_at', Q.eq(null)),
    Q.sortBy('rank', Q.asc),
  );

  @lazy
  careTeam = this.collections
    .get('users')
    .query(
      Q.where('deleted_at', null),
      Q.on(
        'care_team_participants',
        Q.and(
          Q.where('patient_id', Q.eq(this.id)),
          Q.where('deleted_at', null),
        ),
      ),
    );

  @lazy
  appointments = this.collections
    .get('appointments')
    .query(
      Q.on('participants', 'patient_id', this.id),
      Q.where('deleted_at', null),
      Q.sortBy('date', Q.asc),
    );

  @lazy
  futureAppointments = this.collections
    .get('appointments')
    .query(
      Q.where('deleted_at', null),
      Q.on('participants', 'patient_id', this.id),
      Q.sortBy('created_at', Q.desc),
      Q.take(1),
    );

  @children('programs') programs;

  @lazy activePrograms = this.programs.extend(Q.where('deleted_at', null));

  @lazy skills = this.programs.extend(
    Q.where('type', 'skill'),
    Q.where('deleted_at', null),
  );

  @lazy behaviors = this.programs.extend(
    Q.where('type', 'behavior'),
    Q.where('deleted_at', null),
  );

  @children('authorizations') authorizations;

  @lazy activeAuthorizations = this.authorizations.extend(
    Q.where('deleted_at', null),
    Q.sortBy('start_date', Q.desc),
  );

  @children('environmental_factors') environmentalFactors;

  @lazy activeEnvironmentalFactors = this.environmentalFactors.extend(
    Q.where('deleted_at', null),
    Q.sortBy('date', Q.desc),
  );

  @lazy sortedEnvironmentalFactors = this.environmentalFactors.extend(
    Q.where('deleted_at', null),
    Q.sortBy('date', Q.asc),
  );

  @lazy activeNotes = this.collections
    .get('notes')
    .query(
      Q.where('entity_id', this.id),
      Q.where('entity', 'patient'),
      Q.where('deleted_at', null),
      Q.sortBy('created_at', Q.desc),
    );

  @children('progress_reports') progressReports;

  @lazy submittedProgressReports = this.progressReports.extend(
    Q.where('deleted_at', null),
    Q.where('submitted_at', Q.notEq(0)),
    Q.sortBy('start_date', Q.desc),
  );

  @lazy incompleteProgressReports = this.progressReports.extend(
    Q.where('deleted_at', null),
    Q.where('submitted_at', Q.eq(0)),
    Q.sortBy('start_date', Q.desc),
  );

  @children('documents') documents;

  @lazy activeDocuments = this.documents.extend(Q.where('deleted_at', null));

  @lazy starredDocuments = this.documents.extend(
    Q.where('starred', true),
    Q.where('deleted_at', null),
  );

  @children('caregivers') caregivers;

  @lazy activeCaregivers = this.caregivers.extend(Q.where('deleted_at', null));

  @children('medications') medications;

  @lazy activeMedications = this.medications.extend(
    Q.where('deleted_at', null),
  );

  @children('diagnoses') diagnoses;

  @lazy activeDiagnoses = this.diagnoses.extend(
    Q.where('deleted_at', null),
    Q.sortBy('rank', Q.asc),
  );

  @lazy
  activeTags = this.collections
    .get('tags')
    .query(
      Q.where('enabled', true),
      Q.where('deleted_at', null),
      Q.on(
        'taggables',
        Q.and(Q.where('entity_id', this.id), Q.where('deleted_at', null)),
      ),
    );

  @relation('users', 'staff_id') staff;
  @relation('credentials', 'primary_id') primary;
  @relation('credentials', 'referring_id') referring;
  @relation('credentials', 'rendering_id') rendering;
  @relation('credentials', 'bill_physician_id') billingPhysician;
  @relation('billing_entities', 'bill_by_id') billingEntity;

  @text('identifier') identifier;
  @text('first_name') firstName;
  @text('middle_name') middleName;
  @text('last_name') lastName;
  @text('gender') gender;
  @date('birth_date') birthDate;
  @json('address', value => value) address;
  @text('ssn') ssn;
  @date('start_date') startDate;
  @text('state') state;
  @text('staff_id') staffId;
  @field('kafka') kafka;
  @date('intake_date') intakeDate;
  @text('primary_id') primaryId;
  @field('same_as_primary') sameAsPrimary;
  @text('rendering_id') renderingId;
  @text('referring_id') referringId;
  @text('school_id') schoolId;
  @text('bill_by_id') billById;
  @field('bill_by_physician') billByPhysician;
  @field('bill_physician_id') billPhysicianId;
}
