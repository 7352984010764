import React, {useEffect} from 'react';
import {TextInput} from 'react-native-paper';
import {compose} from 'recompose';
import withObservables from '@nozbe/with-observables';
import {ReportComponent} from 'src/modules/patients/components';
import {useStyle} from 'src/providers/style';
import {withDatabase} from '@nozbe/watermelondb/DatabaseProvider';
import {FormProvider, useForm, useFormContext, useWatch} from 'react-hook-form';
import {ProgramGraph} from 'src/modules/reports/components';
import {of} from 'rxjs';
import HookFormSwitchInput from 'src/hook-form-wrapper/switch-input';
import {ProgressReportProgram} from 'src/models';
import {View} from 'react-native';
import {default as HookDateInput} from 'src/hook-form-wrapper/date-input';
import {Separator} from 'src/common-components/atoms';

const ProgressReportProgramGraph = ({
  authProgram,
  program,
  clientEnvs,
  shouldShowMenu = false,
}: any) => {
  const styles = useStyle();
  const title = program.type === 'skill' ? 'Skill Goals' : 'Behavior Goals';
  const {control} = useFormContext();

  const methods = useForm({
    defaultValues: {
      startDate: authProgram?.startDate,
      endDate: authProgram?.endDate,
    },
  });

  const watchedInfo = useWatch({
    name: 'authPrograms.' + authProgram.id,
    control,
  });

  const watchedInclude = useWatch({
    name: 'authPrograms.' + authProgram.id + '_include',
    control,
  });

  const watchedGraphInclude = useWatch({
    name: 'authPrograms.' + authProgram.id + '_graph_include',
    control,
  });

  const watchedStartDate = useWatch({
    name: 'startDate',
    control: methods.control,
  });

  const watchedEndDate = useWatch({
    name: 'endDate',
    control: methods.control,
  });

  useEffect(() => {
    authProgram.updateEntity({
      programInfo: watchedInfo,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchedInfo]);

  useEffect(() => {
    authProgram.updateEntity({
      programInclude: watchedInclude,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchedInclude]);

  useEffect(() => {
    authProgram.updateEntity({
      graphInclude: watchedGraphInclude,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchedGraphInclude]);

  useEffect(() => {
    authProgram.updateEntity({
      startDate: watchedStartDate,
      endDate: watchedEndDate,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchedStartDate, watchedEndDate]);

  return (
    <View style={[styles.width80p]}>
      <ReportComponent
        title={title}
        label={
          'Select the information that you would like to include into the report.'
        }
        name={'authPrograms.' + authProgram.id}
        children={
          <>
            <TextInput
              style={styles.input}
              mode={'outlined'}
              placeholder="Program Name"
              label="Program Name"
              value={program?.name}
              disabled
            />
            <FormProvider {...methods}>
              <View style={[styles.container, styles.paddingMTop]}>
                <HookDateInput
                  name={'startDate'}
                  label={'Start Date'}
                  placeholder={'Start Date'}
                  required={false}
                  showHelper={false}
                />
                <Separator width={10} />
                <HookDateInput
                  name={'endDate'}
                  label={'End Date'}
                  placeholder={'End Date'}
                  required={false}
                  showHelper={false}
                />
              </View>
            </FormProvider>
            <ProgramGraph
              programId={program?.id}
              envs={clientEnvs}
              startDate={authProgram.startDate}
              endDate={authProgram.endDate}
              showTable={false}
              displayProgramObjective={true}
              canDownload={false}
              shouldShowMenu={shouldShowMenu}
            />
            <HookFormSwitchInput
              label={'Include Graph in Report'}
              name={'authPrograms.' + authProgram.id + '_graph_include'}
            />
          </>
        }
      />
    </View>
  );
};

export default compose(
  withDatabase,
  withObservables(['programId'], ({programId, database, patient}: any) => ({
    authProgram: database
      .get(ProgressReportProgram.table)
      .findAndObserve(programId),
    clientEnvs: patient?.activeEnvironmentalFactors || of([]),
  })),
  withObservables(['authProgram'], ({authProgram}: any) => ({
    program: authProgram.program,
  })),
)(ProgressReportProgramGraph);
