import React, {useEffect, useRef, useState} from 'react';
import {ScrollView, View} from 'react-native';
import {BaseScreen, Menu} from 'src/design-system';
import withState from 'src/redux/wrapper';
import Icon from 'react-native-vector-icons/MaterialCommunityIcons';
import {useStyle} from 'src/providers/style';
import Review from '../progress-report-screen/components/review';
import {RHButton} from 'src/common-components/custom-ui-helpers';
import {Colors} from 'src/styles';
import {compose} from 'recompose';
import {withDatabase} from '@nozbe/watermelondb/DatabaseProvider';
import withObservables from '@nozbe/with-observables';
import {useNavigation} from '@react-navigation/native';
import BackButton from 'src/navigation/components/back-button';
import moment from 'moment';
import {PDFGenerator} from 'src/common-components/pdf-export/src/pageResize';
import {ProgressReport} from 'src/models';
import {replace} from 'src/navigation/utils/replace';
import {FormProvider, useForm} from 'react-hook-form';

const ProgressReportRecapScreen = ({progressReport, patient, route}: any) => {
  const styles = useStyle();
  const navigation = useNavigation();
  const [loading, setLoading] = useState(false);
  const reviewRef = useRef();

  const methods = useForm({
    defaultValues: {
      startDate: progressReport.startDate,
      endDate: progressReport.endDate,
    },
  });

  const exportPDF = async () => {
    const pdfGenerator = new PDFGenerator();
    setLoading(true);

    const fileName = `${patient.firstName}_${
      patient.lastName
    }_progress_report_export_${moment(progressReport.submittedAt).format(
      'L LT',
    )}`.replace(/[:/ ]/g, '_');

    const {
      clientInfoRef,
      backgroundRef,
      assessmentsRef,
      goalsRef,
      behaviorGoalsRefs,
      skillGoalsRefs,
      caregiverRef,
      summaryRef,
    } = reviewRef?.current;

    const sections = [
      clientInfoRef,
      backgroundRef,
      assessmentsRef,
      goalsRef,
      behaviorGoalsRefs,
      skillGoalsRefs,
      caregiverRef,
      summaryRef,
    ];

    const dataArr: any[] = [];

    const captureInBatches = async () => {
      for (const ref of sections) {
        if (ref && ref.current) {
          if (Array.isArray(ref.current)) {
            for (const innerRef of ref.current) {
              if (innerRef && innerRef.current) {
                await new Promise(resolve => setTimeout(resolve, 0));
                const image = await innerRef.current.capture();
                dataArr.push(image);
              }
            }
          } else {
            await new Promise(resolve => setTimeout(resolve, 0));
            const image = await ref.current.capture();
            dataArr.push(image);
          }
        }
      }
    };

    await captureInBatches();

    const isDownloaded = await pdfGenerator.generatePdf(dataArr, fileName);
    if (isDownloaded) {
      setLoading(false);
    }
  };

  const options = [
    {
      onPress: exportPDF,
      title: 'PDF',
      icon: 'file-pdf-box',
    },
  ];

  const navigateBack = () => {
    if (navigation.canGoBack() && !route?.params?.block) {
      navigation.goBack();
    } else {
      navigation.dispatch(
        replace('PatientProfile', {
          screen: 'Progress Reports',
          patientId: progressReport.patient.id,
        }),
      );
    }
  };

  const changeNavigateBack = () => {
    navigation.setOptions({
      // eslint-disable-next-line react/no-unstable-nested-components
      headerLeft: () => <BackButton customPress={() => navigateBack()} />,
    });
  };

  useEffect(() => {
    changeNavigateBack();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <BaseScreen header={false}>
      <ScrollView style={[styles.flex, styles.paddingBottom]}>
        <View style={[styles.row, styles.justifyEnd, styles.padding]}>
          <Menu
            anchor={
              <RHButton
                loading={loading}
                secondary
                textColor={Colors.RAVEN_BLACK}
                mode="outlined">
                {<Icon name={'export-variant'} size={18} />}
                {'  '}EXPORT
              </RHButton>
            }
            options={options}
          />
        </View>
        <FormProvider {...methods}>
          <Review
            progressReport={progressReport}
            fromRecap={true}
            reviewRef={reviewRef}
            shouldShowMenu={true}
          />
        </FormProvider>
      </ScrollView>
    </BaseScreen>
  );
};

export default compose(
  withDatabase,
  withState,
  withObservables(['route'], ({route, database}: any) => ({
    progressReport: database
      .get(ProgressReport.table)
      .findAndObserve(route.params.id),
  })),
  withObservables(['progressReport'], ({progressReport}: any) => ({
    patient: progressReport.patient,
  })),
)(ProgressReportRecapScreen);
