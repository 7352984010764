import React, {useState} from 'react';
import {View, TouchableOpacity, Text} from 'react-native';
import Collapsible from 'react-native-collapsible';
import {IconButton} from 'react-native-paper';
import {Colors, Typography} from 'src/styles';
import {useStyle} from 'src/providers/style';
import {DataItem} from 'src/design-system';
import {NPI, TIN} from 'src/hook-form-inputs';
import {Display as TaxonomyDisplay} from 'src/hook-form-inputs/taxonomy';
import {Display as AddressDisplay} from 'src/hook-form-inputs/address';

const BillingEntityListItem = ({
  billingEntity,
  canDelete,
  deleteCallback,
  canEdit,
  editCallBack,
}: any) => {
  const styles = useStyle();
  const [collapsed, setCollapsed] = useState(true);

  return (
    <View style={[styles.card]}>
      <TouchableOpacity onPress={() => setCollapsed(!collapsed)}>
        <View
          style={[
            styles.flex,
            styles.row,
            styles.justifySpaceBetween,
            styles.alignCenter,
            styles.paddingL,
          ]}>
          <Text style={[Typography.P2_MEDIUM]}>{billingEntity?.name}</Text>
          <View
            style={[
              styles.row,
              styles.justifySpaceBetween,
              styles.alignCenter,
            ]}>
            {canEdit ? (
              <IconButton
                color={Colors.RAVEN_BLACK}
                icon="pencil"
                onPress={() => editCallBack(billingEntity)}
              />
            ) : (
              <></>
            )}
            {canDelete ? (
              <IconButton
                color={Colors.RAVEN_BLACK}
                icon="delete"
                size={16}
                onPress={() => deleteCallback(billingEntity)}
              />
            ) : (
              <></>
            )}
            <IconButton
              color={Colors.RAVEN_BLACK}
              icon={!collapsed ? 'chevron-up' : 'chevron-down'}
              onPress={() => setCollapsed(!collapsed)}
            />
          </View>
        </View>
      </TouchableOpacity>
      <Collapsible
        collapsed={collapsed}
        style={[styles.paddingLBottom]}
        renderChildrenCollapsed={false}>
        <View style={[styles.row]}>
          <View
            style={[
              styles.flex,
              styles.column,
              styles.paddingHorizontal,
              styles.paddingLBottom,
            ]}>
            <DataItem>
              <Text style={[Typography.H6]}>Organization Name</Text>
              <Text style={[Typography.P3]}>{billingEntity?.name}</Text>
            </DataItem>
            <DataItem>
              <Text style={[Typography.H6]}>DBA (Doing Business As)</Text>
              <Text style={[Typography.P3]}>{billingEntity?.dba}</Text>
            </DataItem>
            <NPI.Display value={billingEntity.npi} />
            <TIN.Display value={billingEntity.tin} />
            <TaxonomyDisplay value={billingEntity.taxonomy} />
            <AddressDisplay value={billingEntity.address} type={'Billing'} />
          </View>
          <View style={[styles.row, styles.width80]} />
        </View>
      </Collapsible>
    </View>
  );
};

export default BillingEntityListItem;
