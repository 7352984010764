import React from 'react';
import {compose} from 'recompose';
import withObservables from '@nozbe/with-observables';
import {withDatabase} from '@nozbe/watermelondb/DatabaseProvider';
import {Q} from '@nozbe/watermelondb';
import withState from 'src/redux/wrapper';
import {FlatList, ScrollView} from 'react-native';
import {useStyle} from 'src/providers/style';
import NoResults from 'src/common-components/noResults';
import PatientAuthorizationItem from 'src/modules/patients/components/authorization-item';
import {Authorization} from 'src/models';

const AuthorizationsList = ({
  authorizations,
  role,
  setEdit,
  setItemId,
  patient,
  setSubmitting,
  setShowDelete,
  archiveCallback,
  methods,
}: any) => {
  const styles = useStyle();
  return (
    <ScrollView style={[styles.flex]}>
      {authorizations.length === 0 ? (
        <NoResults message={'No Authorizations found.'} />
      ) : (
        <FlatList
          scrollEnabled={true}
          data={[...authorizations].sort(
            (a: any, b: any) => a.startDate - b.startDate,
          )}
          keyExtractor={item => item.id}
          renderItem={({item}: any) => (
            <PatientAuthorizationItem
              authorizationItem={item}
              canEdit={role?.authorizationEdit}
              canDelete={role?.authorizationDelete}
              patient={patient}
              editSelected={() => {
                setEdit(true);
                setItemId(item.id);
                methods.reset({
                  authorizationNumber: item.authorizationNumber,
                  startDate: item.startDate,
                  endDate: item.endDate,
                  payerId: item.payerId,
                  cpt97151: item.cpt97151 || '0',
                  cpt97152: item.cpt97152 || '0',
                  cpt97153: item.cpt97153 || '0',
                  cpt97154: item.cpt97154 || '0',
                  cpt97155: item.cpt97155 || '0',
                  cpt97156: item.cpt97156 || '0',
                  cpt97157: item.cpt97157 || '0',
                  cpt0373T: item.cpt0373T || '0',
                  cpt0362T: item.cpt0362T || '0',
                  cptT1024: item.cptT1024 || '0',
                  cpt92507: item.cpt92507 || '0',
                  cpt92508: item.cpt92508 || '0',
                  cpt92526: item.cpt92526 || '0',
                  cpt92609: item.cpt92609 || '0',
                  cpt92522: item.cpt92522 || '0',
                  cpt92523: item.cpt92523 || '0',
                  cpt92610: item.cpt92610 || '0',
                  cptH2019: item.cptH2019 || '0',
                  cptT2024: item.cptT2024 || '0',
                  cptH0032: item.cptH0032 || '0',
                  cpt90791: item.cpt90791 || '0',
                  cpt90837: item.cpt90837 || '0',
                  cptH0031: item.cptH0031 || '0',
                  cptH2012: item.cptH2012 || '0',
                  cptS5111: item.cptS5111 || '0',
                  cptH2014: item.cptH2014 || '0',
                  cptH0046: item.cptH0046 || '0',
                });
                setSubmitting(true);
              }}
              deleteSelected={() => {
                setShowDelete(item);
              }}
              archiveCallback={archiveCallback}
            />
          )}
        />
      )}
    </ScrollView>
  );
};

export default compose(
  withDatabase,
  withState,
  withObservables(['filter'], ({database, filter, patient}: any) => {
    const queries = [];
    if (
      filter?.authorizationState &&
      filter?.authorizationState.includes('archived')
    ) {
      queries.push(Q.where('state', 'archived'));
    } else {
      queries.push(Q.where('state', Q.notEq('archived')));
    }

    return {
      authorizations: database
        .get(Authorization.table)
        .query(
          ...queries,
          Q.where('deleted_at', null),
          Q.where('patient_id', patient.id),
          Q.sortBy('start_date', Q.desc),
        ),
    };
  }),
)(AuthorizationsList);
