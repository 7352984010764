import React from 'react';
import HookFormInput from 'src/hook-form-wrapper/form-input';
import {View} from 'react-native';
import {useForm, FormProvider} from 'react-hook-form';
import {RHButton} from 'src/common-components/custom-ui-helpers';
import {useStyle} from 'src/providers/style';
import {useTranslations} from 'src/providers/translation';
import AddressAutocompleteInput from 'src/hook-form-wrapper/address-input';
import {InputGroup} from 'src/design-system';
import TaxonomyInput from 'src/hook-form-inputs/taxonomy';
import Tin from 'src/hook-form-inputs/tin';
import {NPI} from 'src/hook-form-inputs';
import * as Yup from 'yup';
import {yupResolver} from '@hookform/resolvers/yup';

interface Props {
  onAssign: any;
  billingEntity: any;
}

const BillingEntityForm = ({onAssign, billingEntity}: Props) => {
  const styles = useStyle();
  const translations = useTranslations();
  const validationSchema = Yup.object({
    name: Yup.string().required(),
    address: Yup.object({
      placeId: Yup.string().required('Address is required'),
    }).test('isValidAddress', 'Address is required', function (value) {
      return Object.keys(value).length;
    }),
  });
  const initialValues = {
    name: billingEntity?.name || '',
    dba: billingEntity?.dba || '',
    npi: billingEntity?.npi || '',
    tin: billingEntity?.tin || '',
    taxonomy: billingEntity?.taxonomy || [],
    address: billingEntity?.address,
  };

  const methods = useForm({
    defaultValues: initialValues,
    resolver: yupResolver(validationSchema),
  });

  return (
    <FormProvider {...methods}>
      <HookFormInput name={'name'} label={'Organization Name'} />
      <HookFormInput name={'dba'} label={'DBA (Doing Business As)'} />
      <InputGroup right={<Tin />} left={<NPI.Input />} />
      <TaxonomyInput />
      <AddressAutocompleteInput
        name={'address'}
        label={'Billing Address'}
        fullAddressPreview={false}
      />
      <View style={[styles.row, styles.alignCenter, styles.justifyEnd]}>
        <RHButton
          secondary
          mode="contained"
          onPress={methods.handleSubmit(onAssign)}>
          {translations('save_button')}
        </RHButton>
      </View>
    </FormProvider>
  );
};

export default BillingEntityForm;
