import React from 'react';
import {ScrollView, View} from 'react-native';
import {useStyle} from 'src/providers/style';
import {Program} from 'src/models';
import {FormProvider, useForm, useWatch} from 'react-hook-form';
import {subtractNMonths} from 'src/common-utils/subtractNMonths';
import {ProgramGraph} from 'src/modules/reports/components';
import {of} from 'rxjs';
import {withDatabase} from '@nozbe/watermelondb/DatabaseProvider';
import withObservables from '@nozbe/with-observables';
import {compose} from 'recompose';
import {Input as DatePeriodInput} from 'src/hook-form-inputs/date-period-buttons';

interface Props {
  program: Program;
  clientEnvs: any[];
}

const Progress = ({program, clientEnvs}: Props) => {
  const styles = useStyle();
  const methods = useForm({
    defaultValues: {
      startDate: subtractNMonths(1),
      endDate: new Date(),
      target: '',
      objective: false,
      format: false,
    },
  });

  const watchedStartDate = useWatch({
    control: methods.control,
    name: 'startDate',
  });
  const watchedEndDate = useWatch({control: methods.control, name: 'endDate'});

  return (
    <FormProvider {...methods}>
      <ScrollView style={[]}>
        <>
          <View
            style={[
              styles.paddingHorizontal,
              styles.paddingTop,
              styles.alignSelfStart,
            ]}>
            <DatePeriodInput name={'startDate'} />
          </View>
          <ProgramGraph
            programId={program?.id}
            envs={clientEnvs}
            startDate={watchedStartDate}
            endDate={watchedEndDate}
            collapsable={false}
            showTable={true}
          />
        </>
      </ScrollView>
    </FormProvider>
  );
};

export default compose(
  withDatabase,
  withObservables(['client'], ({patientId, database}: any) => {
    return {
      patient: database.get('patients').findAndObserve(patientId),
    };
  }),
  withObservables(['patient'], ({patient}: any) => {
    return {
      clientEnvs: patient?.activeEnvironmentalFactors || of([]),
    };
  }),
)(Progress);
