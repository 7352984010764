import React, {useEffect, useState} from 'react';
import FullCalendar, {EventContentArg} from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import listPlugin from '@fullcalendar/list';
import '@fullcalendar/common/main.css';
import moment from 'moment';
import {endOfWeek, startOfDay, startOfWeek} from 'date-fns';
import {useDatabase} from '@nozbe/watermelondb/hooks';
import {Q} from '@nozbe/watermelondb';
import {Colors} from 'src/styles';
import {appointmentLocations} from 'src/form-inputs/appointment-location-input';
import {useDimensions} from '@react-native-community/hooks';
import StyleWrapper from 'src/modules/scheduling/components/web-calendar-wrapper';
import CalendarCard from 'src/modules/scheduling/components/calendar-card';
import StatusLegend from 'src/modules/scheduling/components/status-legend';
import LocationLegend from 'src/modules/scheduling/components/location-legend';
import CancellationLegend from 'src/modules/scheduling/components/cancellation-legend';
import {useSelector} from 'react-redux';
import {RRule, RRuleSet, rrulestr} from 'rrule';
import CancellationListItem from 'src/modules/scheduling/components/cancellation-list-item';
import {cancelReason} from 'src/modules/scheduling/components/cancellation-list-item';
import AppointmentListItem from 'src/modules/scheduling/components/appointment-list-item';
import InProgressCalendarItem from 'src/modules/scheduling/components/in-progress-calendar-item';
import AppointmentCalendarItem from 'src/modules/scheduling/components/appointment-calendar-item';
import NotDraggableModal from 'src/modules/scheduling/components/not-draggable-modal';
import EditRecurringAppointment from 'src/modules/scheduling/components/edit-recurring-appointment';
import {Appointment, Participant, User} from 'src/models';
import {Platform, View} from 'react-native';
import CsvExport from 'src/common-components/csv-export/components';
import {useStyle} from 'src/providers/style';
// import sync from 'src/providers/watermelondb/sync';
import Spinner from 'react-native-loading-spinner-overlay';

const Calendar = ({
  assignedOnly,
  profile,
  filters,
  update,
  canAdd,
  addCallback,
  canEdit,
  editCallback,
  canDelete,
  deleteCallback,
}: any) => {
  const dimensions = useDimensions();
  const database = useDatabase();
  const styles = useStyle();

  const [items, setItems] = useState<any>([]);
  const [dates, setDates] = useState({});
  const [selectedEvent, setSelectedEvent] = useState(false);
  const [cannotMoveEvent, setCannotMoveEvent] = useState(false);
  const [pastDataSynced] = useState(false);
  const [isLoading] = useState(false);

  const [editRecurringAppointment, setEditRecurringAppointment] = useState<
    boolean | any
  >(false);

  const {selectedGroup, userId} = useSelector(state => state.authentication);
  const threeMonthsAgo = new Date(
    new Date().getFullYear(),
    new Date().getMonth() - 3,
    new Date().getDate(),
  ).getTime();

  const getAppointmentColor = async (
    appointment: any,
    date: any,
    supervision: boolean,
  ) => {
    let appointmentColor: string | undefined = '';
    if (filters.scheduleBy === 'location') {
      appointmentColor = appointmentLocations.find(
        (appointmentLocation: any) =>
          appointmentLocation.value === appointment?.location,
      )?.color;
    } else if (filters.scheduleBy === 'status') {
      let sessions = await database
        .get('sessions')
        .query(
          Q.where('type', supervision ? 'supervision' : 'session'),
          Q.where('appointment_id', appointment.id),
          Q.where('session_date', moment(date).format('YYYY-MM-DD')),
          Q.where('deleted_at', null),
          Q.sortBy('end_timestamp', Q.desc),
        )
        .fetch();

      if (sessions && sessions?.length > 0) {
        const session = sessions[0];

        if (!session?.startTimestamp) {
          // not started
          appointmentColor = Colors.TEAL_500;
        } else if (session?.startTimestamp && !session?.endTimestamp) {
          // in progress
          appointmentColor = Colors.LIME_500;
        } else if (session?.endTimestamp && !session.submissionTimestamp) {
          // missing value
          appointmentColor = Colors.RUBY_500;
        } else {
          // completed
          appointmentColor = Colors.ARCTIC;
        }
      } else {
        // not started
        appointmentColor = Colors.TEAL_500;
      }
    } else if (filters.scheduleBy === 'cancel') {
      if (appointment?.cancellationReason === 'staff') {
        appointmentColor = Colors.TEAL_500;
      } else if (appointment?.cancellationReason === 'client') {
        appointmentColor = Colors.LIME_500;
      } else if (appointment?.cancellationReason === 'noShow') {
        appointmentColor = Colors.RUBY_500;
      } else if (appointment?.cancellationReason === 'absent') {
        appointmentColor = Colors.VIOLET_500;
      } else if (appointment?.cancellationReason === 'error') {
        appointmentColor = Colors.TORCH_RED_LIGHT;
      } else {
        appointmentColor = Colors.ORANGE;
      }
    }
    return appointmentColor;
  };

  const recalculateAppointments = async (
    appointmentStartDate: Date,
    appointmentEndDate: Date,
  ) => {
    const locationQuery = [];
    if (filters?.locations && filters.locations.length) {
      locationQuery.push(Q.where('location', Q.oneOf(filters.locations)));
    }

    const userStore = {};
    const clients = [];
    const users = [];
    for (const participant of filters.participants) {
      if (participant.type === 'patient') {
        clients.push(participant);
      } else {
        userStore[participant.value] = participant;
        users.push(participant);
      }
    }

    let clientQueries: [Q.On[]] | any[] = [];
    if (clients.length) {
      clientQueries = [
        Q.on(
          'participants',
          Q.and(
            Q.where('patient_id', Q.oneOf(clients.map(client => client.value))),
            Q.where('deleted_at', null),
          ),
        ),
      ];
    } else {
      clientQueries = [];
    }

    let usersQueries: [Q.On[]] | any[] = [];
    const assignedOnlyQuery = assignedOnly
      ? [
          Q.on(
            'participants',
            Q.and(
              Q.where('user_id', Q.eq(profile.id)),
              Q.where('deleted_at', null),
            ),
          ),
        ]
      : [];
    if (assignedOnly) {
      usersQueries = [
        Q.on(
          'participants',
          Q.and(
            Q.where('user_id', Q.eq(profile.id)),
            Q.where('deleted_at', null),
          ),
        ),
      ];
    }
    let billingCodeQueries = [];
    if (filters?.types && filters.types.length) {
      const types = [];
      for (const code of filters.types) {
        types.push(Q.where('cpt', Q.includes(code)));
      }
      billingCodeQueries.push(Q.or(types));
    }
    if (users.length) {
      usersQueries = [
        ...usersQueries,
        Q.on(
          'participants',
          Q.and(
            Q.where('user_id', Q.oneOf(users.map(user => user.value))),
            Q.where('deleted_at', null),
            ...billingCodeQueries,
          ),
        ),
      ];
    } else {
      if (billingCodeQueries.length > 0) {
        usersQueries = [
          ...assignedOnlyQuery,
          Q.on(
            'participants',
            Q.and(Q.where('deleted_at', null), ...billingCodeQueries),
          ),
        ];
      } else {
        usersQueries = assignedOnlyQuery;
      }
    }

    const filterCancelQuery =
      filters?.scheduleBy === 'cancel'
        ? Q.where('deleted_at', Q.notEq(null))
        : Q.where('deleted_at', null);
    const queriedAppointments = await database
      .get('appointments')
      .query(
        Q.where('_partition', selectedGroup),
        Q.or(
          Q.and(
            Q.where('rrule', ''),
            Q.where('date', Q.gt(appointmentStartDate.getTime())),
            Q.where('date', Q.lt(appointmentEndDate.getTime())),
          ),
          Q.and(
            Q.where('rrule', Q.notEq('')),
            Q.or(
              Q.and(
                Q.where(
                  'start_timestamp',
                  Q.gt(appointmentStartDate.getTime()),
                ),
                Q.where('start_timestamp', Q.lt(appointmentEndDate.getTime())),
              ),
              Q.and(
                Q.where(
                  'start_timestamp',
                  Q.lt(appointmentStartDate.getTime()),
                ),
                Q.or(
                  Q.where('end_timestamp', Q.eq(0)),
                  Q.where(
                    'end_timestamp',
                    Q.gt(appointmentStartDate.getTime()),
                  ),
                ),
              ),
            ),
          ),
        ),
        ...usersQueries,
        ...clientQueries,
        ...locationQuery,
        filterCancelQuery,
      );

    const curatedAppointments = [];
    for (const appointment of queriedAppointments) {
      const appointmentPatients = await appointment.patients.fetch();
      const patient = appointmentPatients?.[0];
      let clientName = `${patient?.firstName} ${patient?.lastName}`;

      const staffParticipants = await appointment.staffParticipants.fetch();

      if (appointment.rrule) {
        const rruleSet = rrulestr(appointment.rrule, {
          forceset: true,
        });

        for (const date of rruleSet
          .between(startOfDay(appointmentStartDate), appointmentEndDate)
          .map(rruleDate => {
            const realDate = new Date(
              rruleDate.getFullYear(),
              rruleDate.getMonth(),
              rruleDate.getDate(),
            );
            realDate.setTime(
              rruleDate.getTime() +
                rruleSet.options.dtstart.getTimezoneOffset() * 60 * 1000,
            );
            return realDate;
          })) {
          const exdateFind = rruleSet
            .exdates()
            .findIndex(
              (exdate: any) =>
                moment(exdate).format('MMM DD YYYY') ===
                moment(date).format('MMM DD YYYY'),
            );
          if (exdateFind !== -1) {
            continue;
          }
          for (const staffParticipant of staffParticipants) {
            if (
              !Object.keys(userStore).length ||
              userStore?.[staffParticipant.userId]
            ) {
              const startDate = moment(staffParticipant.startTime);
              const endDate = moment(staffParticipant.endTime);

              const start = moment(date)
                .set({
                  hour: startDate.get('hour'),
                  minute: startDate.get('minute'),
                  second: startDate.get('second'),
                })
                .toDate();
              const end = moment(date)
                .set({
                  hour: endDate.get('hour'),
                  minute: endDate.get('minute'),
                  second: endDate.get('second'),
                })
                .toDate();

              const color = await getAppointmentColor(
                appointment,
                date,
                staffParticipant.supervision,
              );

              curatedAppointments.push({
                id: appointment.id,
                date: date,
                location: appointment.location,
                type: appointment.type,
                start,
                end,
                title: clientName,
                flagColor: color,
                staffColor: userStore?.[staffParticipant?.userId]?.color,
                textColor: userStore?.[staffParticipant?.userId]?.textColor,
                user: staffParticipant.userId,
                cancellationReason: appointment.cancellationReason,
                inProgress: color !== Colors.TEAL_500,
                isRecurring: true,
                deletedBy: appointment?.deletedBy,
              });
            }
          }
        }
      } else {
        for (const staffParticipant of staffParticipants) {
          if (
            !Object.keys(userStore).length ||
            userStore?.[staffParticipant.userId]
          ) {
            const color = await getAppointmentColor(
              appointment,
              appointment.date,
              staffParticipant.supervision,
            );

            const startDate = moment(staffParticipant.startTime);
            const endDate = moment(staffParticipant.endTime);

            const start = moment(appointment.date)
              .set({
                hour: startDate.get('hour'),
                minute: startDate.get('minute'),
                second: startDate.get('second'),
              })
              .toDate();
            const end = moment(appointment.date)
              .set({
                hour: endDate.get('hour'),
                minute: endDate.get('minute'),
                second: endDate.get('second'),
              })
              .toDate();

            curatedAppointments.push({
              id: appointment.id,
              date: appointment.date,
              location: appointment.location,
              start,
              end,
              title: clientName,
              flagColor: color,
              staffColor: userStore?.[staffParticipant?.userId]?.color,
              textColor: userStore?.[staffParticipant?.userId]?.textColor,
              user: staffParticipant.userId,
              cancellationReason: appointment.cancellationReason,
              inProgress: color !== Colors.TEAL_500,
              isRecurring: false,
              cancellationMoreInfo: appointment.cancellationMoreInfo,
              deletedBy: appointment?.deletedBy,
            });
          }
        }
      }
    }
    setItems(curatedAppointments);
  };
  // const checkSync = async (timestamp: any) => {
  //   const startDate = new Date(timestamp);
  //   const monthSpan = new Date(
  //     startDate.getFullYear(),
  //     startDate.getMonth() - 1,
  //     startDate.getDate(),
  //   ).getTime();
  //   let sets = await database
  //     .get('sets')
  //     .query(
  //       Q.and(
  //         Q.where('created_at', Q.lt(timestamp)),
  //         Q.where('created_at', Q.gt(monthSpan)),
  //       ),
  //     )
  //     .fetch();
  //   if (sets.length === 0) {
  //     setIsLoading(true);
  //     await sync(database, false, false, false, false, true);
  //     setIsLoading(false);
  //     setPastDataSync(true);
  //   }
  // };

  useEffect(() => {
    const startTimestamp = new Date(dates?.start).getTime();
    if (
      Platform.OS === 'web' &&
      startTimestamp < threeMonthsAgo &&
      !pastDataSynced
    ) {
      // checkSync(startTimestamp);
    }
    if (dates?.start && dates?.end) {
      recalculateAppointments(
        startOfWeek(dates?.start, {weekStartsOn: 6}),
        endOfWeek(dates?.end),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dates, filters, editRecurringAppointment, update]);

  const exportAppointments = async () => {
    let flatAppointments = [
      [
        'Date',
        'Start Time',
        'End Time',
        'Client',
        'Staff',
        'Cancelled By',
        'Cancellation Reason',
      ],
    ];
    const filteredItems = items
      .filter((item: any) =>
        moment(new Date(item?.date)).isBetween(dates?.start, dates?.end),
      )
      .sort((a: any, b: any) => a.date - b.date);
    for (const item of filteredItems) {
      let cancelledBy;
      const appointment = await database.get(Appointment.table).find(item?.id);
      if (item?.deletedBy) {
        cancelledBy = await database.get(User.table).find(item?.deletedBy);
      }

      const patients = await appointment.patients;
      const staff = await appointment.staff;
      flatAppointments.push([
        moment(item?.date).format('MM/DD/YYYY'),
        moment(appointment?.start).format('hh:mm A'),
        moment(appointment?.end).format('hh:mm A'),
        patients
          .map((patient: any) => patient?.firstName + ' ' + patient?.lastName)
          .join(','),
        staff
          .map(
            (practitioner: any) =>
              practitioner?.firstName + ' ' + practitioner?.lastName,
          )
          .join(','),
        cancelledBy
          ? `${cancelledBy?.firstName} ${cancelledBy?.lastName}`
          : '-',
        cancelReason(item?.cancellationReason)?.name +
          (item?.cancellationMoreInfo
            ? ' - ' + item?.cancellationMoreInfo
            : ''),
      ]);
    }
    return flatAppointments;
  };

  const renderEventContent = (eventContent: EventContentArg) => {
    const {type} = eventContent.view;

    const {
      flagColor,
      staffColor,
      cancellationReason,
      inProgress,
      cancellationMoreInfo,
      deletedBy,
    } = eventContent.event.extendedProps;
    const {textColor, start, end, title, id} = eventContent.event;

    const apptDuration = moment(end).diff(start, 'minutes');

    return inProgress && type !== 'listWeek' ? (
      <InProgressCalendarItem
        title={title}
        type={type}
        start={start}
        end={end}
        apptDuration={apptDuration}
        staffColor={staffColor}
        textColor={textColor}
        flagColor={flagColor}
      />
    ) : type === 'listWeek' && filters.scheduleBy === 'cancel' ? (
      <CancellationListItem
        title={title}
        cancellationReason={cancellationReason}
        cancellationMoreInfo={cancellationMoreInfo}
        deletedBy={deletedBy}
      />
    ) : type === 'listWeek' ? (
      <AppointmentListItem
        eventContent={eventContent}
        title={title}
        flagColor={flagColor}
        appointmentId={id}
      />
    ) : (
      <AppointmentCalendarItem
        title={title}
        type={type}
        start={start}
        end={end}
        apptDuration={apptDuration}
        staffColor={staffColor}
        textColor={textColor}
        flagColor={flagColor}
      />
    );
  };

  const dragAppointment = async (event: any) => {
    const {inProgress, isRecurring} = event.event.extendedProps;
    if (inProgress) {
      setCannotMoveEvent(true);
      event.revert();
    } else {
      if (canEdit) {
        const {start, end} = event.event;

        if (isRecurring) {
          const appointment = await database
            .get('appointments')
            .find(event.event.id);

          const rruleSet = rrulestr(appointment.rrule, {
            forceset: true,
          });
          const oldEvent = event.oldEvent.start;
          rruleSet.exdate(
            new Date(
              oldEvent.getFullYear(),
              oldEvent.getMonth(),
              oldEvent.getDate(),
              0,
              0,
              0,
              0,
            ),
          );
          await appointment.updateEntity({
            rrule: rruleSet.toString(),
          });
          await database?.write(async () => {
            const createAppointment = await database
              ?.get(Appointment.table)
              .create(entity => {
                entity.partition = selectedGroup;
                entity.location = appointment.location;
                entity.type = appointment.type;
                entity.date = start;
                entity.start = start;
                entity.end = end;
                entity.noteTemplateId = appointment.noteTemplateId;
                entity.createdBy = userId;
                entity.updatedBy = userId;
              });
            for (const participant of await appointment.staffParticipants.fetch()) {
              const startDifference = Math.abs(
                appointment.start.getTime() - participant.startTime.getTime(),
              );
              const endDifference = Math.abs(
                appointment.end.getTime() - participant.endTime.getTime(),
              );
              await database?.get(Participant.table).create(entity => {
                entity.partition = selectedGroup;
                entity.appointment.id = createAppointment.id;
                entity.userId = participant.userId;
                entity.patientId = participant.patientId;
                entity.billable = participant.billable;
                entity.supervision = participant.supervision;
                entity.startTime = new Date(start.getTime() - startDifference);
                entity.endTime = new Date(end.getTime() - endDifference);
                entity.deletedBy = participant.deletedBy;
                entity.deletedAt = participant.deletedAt;
                entity.createdBy = userId;
                entity.updatedBy = userId;
              });
            }
            for (const participant of await appointment.patientParticipants.fetch()) {
              await database?.get(Participant.table).create(entity => {
                entity.partition = selectedGroup;
                entity.appointment.id = createAppointment.id;
                entity.userId = participant.userId;
                entity.patientId = participant.patientId;
                entity.billable = participant.billable;
                entity.supervision = participant.supervision;
                entity.startTime = start;
                entity.endTime = end;
                entity.deletedBy = participant.deletedBy;
                entity.deletedAt = participant.deletedAt;
                entity.createdBy = userId;
                entity.updatedBy = userId;
              });
            }
          });
        } else {
          const appointment = await database
            .get(Appointment.table)
            .find(event.event.id);

          for (const participant of await appointment.staffParticipants.fetch()) {
            const startDifference = Math.abs(
              appointment.start.getTime() - participant.startTime.getTime(),
            );
            const endDifference = Math.abs(
              appointment.end.getTime() - participant.endTime.getTime(),
            );

            await participant.updateEntity({
              startTime: new Date(start.getTime() - startDifference),
              endTime: new Date(end.getTime() - endDifference),
            });
          }

          await appointment.updateEntity({
            date: start,
            start,
            end,
          });
        }
      } else {
        event.revert();
      }
    }
  };

  const onSubmit = async (values: any) => {
    const appointment = await database
      .get('appointments')
      .find(editRecurringAppointment.event.event.id);

    const rruleSet = rrulestr(appointment.rrule, {
      forceset: true,
    });

    const {start, end} = editRecurringAppointment.event.event;
    const todaysDate = start;
    switch (values.action) {
      case 'event':
        rruleSet.exdate(
          new Date(
            todaysDate.getFullYear(),
            todaysDate.getMonth(),
            todaysDate.getDate(),
            0,
            0,
            0,
            0,
          ),
        );
        await appointment.updateEntity({
          rrule: rruleSet.toString(),
        });
        await database?.write(async () => {
          const createAppointment = await database
            ?.get(Appointment.table)
            .create(entity => {
              entity.partition = selectedGroup;
              entity.location = appointment.location;
              entity.type = appointment.type;
              entity.date = todaysDate;
              entity.start = start;
              entity.end = end;
              entity.noteTemplateId = appointment.noteTemplateId;
              entity.createdBy = userId;
              entity.updatedBy = userId;
            });
          for (const participant of await appointment.staffParticipants.fetch()) {
            const startDifference = Math.abs(
              appointment.start.getTime() - participant.startTime.getTime(),
            );
            const endDifference = Math.abs(
              appointment.end.getTime() - participant.endTime.getTime(),
            );
            await database?.get(Participant.table).create(entity => {
              entity.partition = selectedGroup;
              entity.appointment.id = createAppointment.id;
              entity.userId = participant.userId;
              entity.patientId = participant.patientId;
              entity.billable = participant.billable;
              entity.supervision = participant.supervision;
              entity.startTime = new Date(start.getTime() - startDifference);
              entity.endTime = new Date(end.getTime() - endDifference);
              entity.deletedBy = participant.deletedBy;
              entity.deletedAt = participant.deletedAt;
              entity.createdBy = userId;
              entity.updatedBy = userId;
            });
          }
          for (const participant of await appointment.patientParticipants.fetch()) {
            await database?.get(Participant.table).create(entity => {
              entity.partition = selectedGroup;
              entity.appointment.id = createAppointment.id;
              entity.userId = participant.userId;
              entity.patientId = participant.patientId;
              entity.billable = participant.billable;
              entity.supervision = participant.supervision;
              entity.startTime = start;
              entity.endTime = end;
              entity.deletedBy = participant.deletedBy;
              entity.deletedAt = participant.deletedAt;
              entity.createdBy = userId;
              entity.updatedBy = userId;
            });
          }
        });
        break;
      case 'following':
        const ruleSet = new RRuleSet();
        for (const exrule of rruleSet.exrules()) {
          ruleSet.exrule(exrule);
        }
        for (const rdate of rruleSet.rdates()) {
          ruleSet.rdate(rdate);
        }
        for (const exdate of rruleSet.exdates()) {
          ruleSet.exdate(exdate);
        }
        ruleSet.rrule(
          new RRule({
            ...rruleSet.rrules()[0].options,
            until: new Date(
              todaysDate.getFullYear(),
              todaysDate.getMonth(),
              todaysDate.getDate() - 1,
              22,
              59,
            ).setMinutes(moment(rruleSet._dtstart).utcOffset()),
          }),
        );

        await appointment.updateEntity({
          rrule: ruleSet.toString(),
        });

        const rrule = new RRuleSet();
        for (const exrule of rruleSet.exrules()) {
          rrule.exrule(exrule);
        }
        for (const rdate of rruleSet.rdates()) {
          rrule.rdate(rdate);
        }
        for (const exdate of rruleSet.exdates()) {
          rrule.exdate(exdate);
        }

        rrule.rrule(
          new RRule({
            ...rruleSet.rrules()[0].options,
            dtstart: new Date(
              todaysDate.getFullYear(),
              todaysDate.getMonth(),
              todaysDate.getDate(),
            ),
          }),
        );

        await database?.write(async () => {
          const createAppointment = await database
            ?.get(Appointment.table)
            .create(entity => {
              entity.partition = selectedGroup;
              entity.location = appointment.location;
              entity.type = appointment.type;
              entity.date = todaysDate;
              entity.start = start;
              entity.end = end;
              entity.noteTemplateId = appointment.noteTemplateId;
              entity.rrule = rrule.toString();
              entity.createdBy = userId;
              entity.updatedBy = userId;
            });
          for (const participant of await appointment.staffParticipants.fetch()) {
            const startDifference = Math.abs(
              appointment.start.getTime() - participant.startTime.getTime(),
            );
            const endDifference = Math.abs(
              appointment.end.getTime() - participant.endTime.getTime(),
            );
            await database?.get(Participant.table).create(entity => {
              entity.partition = selectedGroup;
              entity.appointment.id = createAppointment.id;
              entity.userId = participant.userId;
              entity.patientId = participant.patientId;
              entity.billable = participant.billable;
              entity.supervision = participant.supervision;
              entity.startTime = new Date(start.getTime() - startDifference);
              entity.endTime = new Date(end.getTime() - endDifference);
              entity.deletedBy = participant.deletedBy;
              entity.deletedAt = participant.deletedAt;
              entity.createdBy = userId;
              entity.updatedBy = userId;
            });
          }
          for (const participant of await appointment.patientParticipants.fetch()) {
            await database?.get(Participant.table).create(entity => {
              entity.partition = selectedGroup;
              entity.appointment.id = createAppointment.id;
              entity.userId = participant.userId;
              entity.patientId = participant.patientId;
              entity.billable = participant.billable;
              entity.supervision = participant.supervision;
              entity.startTime = start;
              entity.endTime = end;
              entity.deletedBy = participant.deletedBy;
              entity.deletedAt = participant.deletedAt;
              entity.createdBy = userId;
              entity.updatedBy = userId;
            });
          }
          return createAppointment;
        });
        break;
      case 'all':
        for (const participant of await appointment.staffParticipants.fetch()) {
          const startDifference = Math.abs(
            appointment.start.getTime() - participant.startTime.getTime(),
          );
          const endDifference = Math.abs(
            appointment.end.getTime() - participant.endTime.getTime(),
          );

          await participant.updateEntity({
            startTime: new Date(start.getTime() - startDifference),
            endTime: new Date(end.getTime() - endDifference),
          });
        }

        await appointment.updateEntity({
          date: start,
          start,
          end,
        });
        break;
    }
    setEditRecurringAppointment(false);
  };

  return (
    <>
      <Spinner visible={isLoading} />

      <EditRecurringAppointment
        show={[editRecurringAppointment, setEditRecurringAppointment]}
        cancelled={() => setEditRecurringAppointment(false)}
        onSubmit={onSubmit}
      />
      <NotDraggableModal show={[cannotMoveEvent, setCannotMoveEvent]} />
      {selectedEvent ? (
        <CalendarCard
          date={selectedEvent.event.start}
          anchor={{
            x: selectedEvent.jsEvent.x,
            y: selectedEvent.jsEvent.y,
          }}
          event={selectedEvent.event}
          canEdit={canEdit}
          editCallback={(appointment: any) => {
            setSelectedEvent(false);
            editCallback(appointment, selectedEvent.event.start);
          }}
          canDelete={canDelete}
          deleteCallback={(appointment: any) => {
            setSelectedEvent(false);
            deleteCallback(appointment, selectedEvent.event.start);
          }}
          onClose={() => setSelectedEvent(false)}
        />
      ) : null}
      <StyleWrapper>
        <FullCalendar
          slotEventOverlap={false}
          plugins={[
            dayGridPlugin,
            timeGridPlugin,
            interactionPlugin,
            listPlugin,
          ]}
          editable={!!canEdit}
          height={dimensions.window.height - 116}
          selectable={true}
          events={items}
          eventContent={renderEventContent}
          initialView={
            filters?.scheduleBy === 'cancel' ? 'listWeek' : 'timeGridWeek'
          }
          headerToolbar={{
            left: 'prev,next today',
            center: 'title',
            right: 'dayGridMonth,timeGridWeek,timeGridDay,listWeek',
          }}
          datesSet={setDates}
          dayMaxEventRows={true}
          views={{
            dayGrid: {
              eventLimit: 4,
              dayMaxEventRows: 5,
            },
          }}
          buttonText={{
            today: 'TODAY',
            month: 'MONTH',
            week: 'WEEK',
            day: 'DAY',
            list: 'LIST',
          }}
          nowIndicator={false}
          eventClick={setSelectedEvent}
          eventDrop={dragAppointment}
          eventResize={dragAppointment}
          dateClick={canAdd ? addCallback : () => {}}
        />
        <View style={[styles.row, styles.justifySpaceBetween]}>
          <View />
          {filters.scheduleBy === 'status' ? (
            <StatusLegend />
          ) : filters.scheduleBy === 'location' ? (
            <LocationLegend />
          ) : filters.scheduleBy === 'cancel' ? (
            <CancellationLegend />
          ) : null}
          <View style={[styles.paddingRight, styles.paddingSMTop]}>
            {filters.scheduleBy === 'cancel' ? (
              <CsvExport
                data={exportAppointments}
                name={`${moment(dates?.start).format('MM/DD/YY')} - ${moment(
                  dates?.end,
                ).format('MM/DD/YY')} Cancellations`}
              />
            ) : null}
          </View>
        </View>
      </StyleWrapper>
    </>
  );
};

export default Calendar;
